import React, { useState, useRef, useEffect } from "react";
import Peer from "peerjs";
import { toastInfo } from "../../../Redux/Reducers/admin/adminPanelSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ModalView from "../consultaions/ModalView";
import moment from "moment/moment";

const VideoCall = ({ socket, consInfo, onlineUsers, modalFunc }) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [doctorCalling, setDoctorCalling] = useState(false);
  const [doctorCallDetails, setDoctorCallDetails] = useState(null);
  const [hasCanceledCall, setHasCanceledCall] = useState(false);
  const [customerLateNoRefundTriggered, setCustomerLateNoRefundTriggered] = useState(false);
  const [stream, setStream] = useState(null);
  const [userId, setUserId] = useState();
  const [isCallIsOn, setIsCallIsOn] = useState(false);
  const [viewVal, setShowVal] = useState(false);
  const [peerInstance, setPeerInstance] = useState(null);
  const [me, setMe] = useState("");

  const myVideo = useRef(null);
  const userVideo = useRef(null);

  const navigate = useNavigate();

  // Initialize PeerJS
  useEffect(() => {
    const initPeer = async () => {
      try {
        const peer = new Peer(undefined, {
          host: process.env.REACT_APP_VIDEOCALL_URL,
          path: "/peerjs",
          secure: true,
        });

        setPeerInstance(peer);

        peer.on("open", (id) => {
          console.log("PeerJS connection opened with ID:", id);
          setMe(id);
        });

        peer.on("call", (incomingCall) => {
          try {
            setDoctorCalling(true);
            setDoctorCallDetails(incomingCall);
            setIsCallIsOn(true);

            incomingCall.on("error", (err) => {
              console.error("Call error:", err);
              cleanupCall();
            });
          } catch (err) {
            console.error("Error handling incoming call:", err);
          }
        });

        peer.on("error", (err) => {
          console.error("PeerJS error:", err);
        });

        socket.on("customerLateNoRefund", () => {
          setCustomerLateNoRefundTriggered(true);
          toastInfo("You are late for the call. No refund will be provided.");
        });

        socket.on("doctor-left", () => {
          toastInfo("Doctor left the call");
          setCallEnded(true);
          modalFunc();
        });
      } catch (err) {
        console.error("Error initializing PeerJS:", err);
      }
    };

    initPeer();

    return () => {
      if (peerInstance) peerInstance.destroy();
      if (stream) stream.getTracks().forEach((track) => track.stop());
    };
  }, [stream]);

  // Fetch Media Stream
  useEffect(() => {
    const fetchMediaStream = async () => {
      try {
        const currentStream = await navigator?.mediaDevices?.getUserMedia({
          video: true,
          audio: true,
        });
        setStream(currentStream);
        if (myVideo.current) myVideo.current.srcObject = currentStream;
      } catch (err) {
        if (err.name === "AbortError") {
          console.error("Media stream aborted:", err);
          toast.error("Unable to access your camera or microphone.");
        } else {
          console.error("Media stream error:", err);
        }
      }
    };

    fetchMediaStream();
  }, []);

  // Listen for doctor starting the call
  useEffect(() => {
    socket.on("call_User", ({ from, name }) => {
      setDoctorCalling(true);
      setDoctorCallDetails({ from, name }); // Save doctor details to answer the call
    });

    return () => {
      socket.off("call_User");
    };
  }, [socket]);

  useEffect(() => {

    socket.on("ice-candidate", ({ candidate }) => {
      console.log(peerInstance, "ICE candidate received:", candidate);
      if (candidate) {
        peerInstance?.current?.signal(candidate);
      }
    });

    return () => socket.off("ice-candidate");
  }, [socket]);


  // Join Call (Answer)
  const joinCall = async() => {
    if (!doctorCallDetails) {
      // toast.error("No incoming call to join.");
      return;
    }
    const { from } = doctorCallDetails;
    // Answer the call with the user's stream
    const incomingCall = peerInstance?.call(from, stream);

    incomingCall?.on("stream", (remoteStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = remoteStream;
        userVideo?.current?.play();
      }
    });

    incomingCall?.on("close", () => {
      console.log("Call ended.");
      setCallEnded(true);
      cleanupCall();
    });

    setCallAccepted(true);
    setDoctorCalling(false); // Hide "Join Call" button
  };

  useEffect(() => {
    if (consInfo) {
      const socketId = onlineUsers?.find(
        (item) => item?.userId == consInfo?.user_id?._id
      )?.socketId;
      if (socketId) {
        setUserId(socketId);
      }
    }
  }, [consInfo, onlineUsers]);


  // Cleanup call
  // const cleanupCall = () => {
  //   setCallAccepted(false);
  //   setCallEnded(true);
  //   setDoctorCalling(false);
  //   setDoctorCallDetails(null);
  // };

  const leaveCall = async () => {
    try {
      // Ensure PeerJS call and media streams are cleaned up
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      if (peerInstance) {
        peerInstance?.destroy(); // Destroy the PeerJS instance
      }
      if (doctorCallDetails) {
        const { from } = doctorCallDetails; // Extract the calling peer ID
        socket.emit("user-disconnected", {
          to: from, // Notify the other peer
          consultationId: consInfo?._id,
        });
      }

      // Reset states and navigate away
      cleanupCall(); // Reset the call state
      toastInfo("You have left the call."); // Show notification
      navigate("/accountHome"); // Navigate to the user's account home

    } catch (error) {
      console.error("Error while leaving the call:", error);
    }
  };


  // const leaveCall = () => {
  //   socket.emit("user-disconnected", {
  //     to: call.from,
  //     consultaionId: consInfo?._id,
  //   });
  //   setCallEnded(true);
  //   setCall({});
  //   setCallAccepted(false);

  //   if (stream) stream.getTracks().forEach(track => track.stop());

  //   connectionRef.current.onDisconnect();
  //   window.location.reload();
  // };

  // ==================================  if dentist joins 10 min late =========================

  useEffect(() => {
    const checkDentistLate = () => {
      if (consInfo?.date && !callAccepted && !hasCanceledCall && !customerLateNoRefundTriggered) {
        const consultationTime = moment(consInfo.date);
        const tenMinutesLater = consultationTime.clone().add(10, "minutes");
        const now = moment();
        if (now.isAfter(tenMinutesLater)) {

          cancelCallAndRefund();
        }
      }
    };

    const interval = setInterval(checkDentistLate, 600000);

    return () => clearInterval(interval);
  }, [callAccepted, consInfo, hasCanceledCall, customerLateNoRefundTriggered]);

  const cancelCallAndRefund = () => {
    if (!hasCanceledCall && !customerLateNoRefundTriggered) {

      setCallEnded(true);
      setHasCanceledCall(true);
      console.log(
        consInfo?._id,
        "Consultation canceled due to dentist lateness."
      );

      socket.emit("cancel_call_due_to_lateness", {
        consultationId: consInfo?._id,
        reason: "Dentist was late by more than 10 minutes",
      });
      navigate("/accountHome");
      toast.error(
        "Call canceled due to dentist's lateness. You will receive a full refund."
      );
    }
  };


  // useEffect(() => {
  //   let timer;
  //   if (isCallIsOn) {
  //     timer = setTimeout(() => {
  //       handleFunctionCall();
  //     }, 600000);
  //   }

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [isCallIsOn]);

  useEffect(() => {
    const consultationTime = moment(consInfo.date);
    const tenMinutesLater = consultationTime.clone().add(10, 'minutes');
    const now = moment();

    let timer;

    if (isCallIsOn) {

      if (now.isAfter(tenMinutesLater)) {
        handleFunctionCall();
      } else {
        timer = setTimeout(() => {
          handleFunctionCall();
        }, 600000);
      }
    }

    return () => {
      clearTimeout(timer);
    };
  }, [isCallIsOn, consInfo.date]);

  const handleFunctionCall = () => {
    toastInfo("No refund")
    console.log('Function called after 1 minute');
    socket.emit("late_status_updated", { customerId: consInfo?.user_id?._id, doctorId: consInfo?.assignedDoctor?._id })
    navigate("/accountHome");
  };

  const cleanupCall = () => {
    setCallAccepted(false);
    setCallEnded(true);
    setDoctorCalling(false);
    setDoctorCallDetails(null);
    setStream(null); // Clear the local stream reference
  };



  return (
    <div>

      <ModalView viewVal={viewVal} setShowVal={setShowVal} />
      <div>
        {/* <div >
      {matches && (<h1>Big Screen</h1>)}
      {!matches && (<h3>Small Screen</h3>)}
    </div> */}
      </div>

      {/* <div className="d-flex justify-content-end mb-3">
        <button
          onClick={leaveCall}
          className={
            callAccepted && !callEnded
              ? "btn btn-primary white-btn small_white_btn"
              : "btn btn-primary white-btn small_white_btn visibility-hidden"
          }
        >
          Leave Call.
        </button>
      </div> */}

      {/* Leave Call Button */}
      {/* {callAccepted && !callEnded && ( */}
      <div className="d-flex justify-content-end mb-3">
        <button
          onClick={leaveCall}
          disabled={!callAccepted}
          className="btn btn-primary white-btn small_white_btn mt-0"
        >
          Leave Call
        </button>
      </div>
      {/* // )} */}


      {/* Video Section */}
      <div className="card mb-4 video_call_section">
        <div className="pb-0">
          <div className="consulatation_card">
            {callAccepted && !callEnded ? (
              <video
                playsInline
                width="626"
                height="323"
                ref={userVideo}
                autoPlay
                className="full-video"
              />
            ) : (
              <img
                src={require("../../../assets/images/lg_video_frame.png")}
                alt="live_chat"
                className="img-fluid large_img"
              />
            )}
            <img
              src={require("../../../assets/images/small_video_frame.png")}
              alt="live_chat"
              className="img-fluid video_call_frame"
            />
            <video
              playsInline
              muted
              ref={myVideo}
              autoPlay
              className="doctor-video"
            />
          </div>
        </div>

        {/* "Join Call" button */}
        {doctorCalling && !callAccepted && (
          <div className="start_call">
            <button onClick={joinCall} className="cre_new">
              Join Call
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCall;
