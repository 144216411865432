import React from "react";
import temperature from "../../../assets/images/sidebar_icons/temperature.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  cancelConsultation,
  cancellationdetailConsultation,
  getBlogs,
  getConsultationDate,
  getMedication,
  getPresriptions,
} from "../../../Redux/Actions/user/userAll";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import MomentFunc from "../../../utils/MomentDateTime";
import ViewPrescription from "./ViewPres";
import moment from "moment";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import  io  from "socket.io-client";

let socket;
socket = io(process.env.REACT_APP_HOST_NAME)


const UpcomigConsultation = ({ setInfo }) => {
  const [viewPres, setPres] = useState(false);
  const [presData, setPresData] = useState(null);
  const [consId, setConsId] = useState();
  const [joinCallDetail, setJoinCallDetail] = useState();
  const [agreeTerms, setAgreeTerms] = useState();
  const PrescriptionRef = useRef();
  const HOST_NAME = process.env.REACT_APP_HOST_NAME;
  const handlePresPrint = useReactToPrint({
    content: () => PrescriptionRef.current,
  });
  const convoDetail = useSelector(
    (state) => state?.consultaions?.upcomingConsultations
  );
  const pricriptions = useSelector(
    (state) =>
      state?.consultaions?.pricriptions?.user?.message?.currentPrescriptions
  );
  const blogs = useSelector((state) => state?.consultaions?.blogs);
  const medication = useSelector((state) => state.consultaions.medication);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(getConsultationDate()).then((res) => {
      if (
        res.payload.user.success == true ||
        res.payload.user.success == false
      ) {
        dispatch(startstopLoading(false));
      }
    });
    dispatch(getPresriptions());
    dispatch(getBlogs());
    dispatch(getMedication());
  }, []);

  const viewPrescription = (pres) => {
    setPresData(pres);
    setPres(true);
  };
  const handleClose = () => {
    setPres(false);
  };

  const handleUpdate = () => {
    sessionStorage.setItem("consId", consId);
    navigate("/new-consultation");
  };

  const handleCancel = (id) => {
    const consulataionData = convoDetail.find((elem) => elem?._id === id);

    if (consulataionData) {
      let obj = {
        consultationId: id,
        paymentIntentId: consulataionData?.payment_id,
      };

      dispatch(cancelConsultation(id));
      socket.emit("cancel_appointment", obj);
    // dispatch(cancellationdetailConsultation(obj));
    } else {
      console.log("Consultation data not found for this id.");
    }
    // dispatch(getConsultationDate());
  };

  const HandleHealthQuestionaire = () => {
    navigate("/my-account/med-history");
  };

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  useEffect(() => {
    sessionStorage.setItem("consIdReschedule", "undefined");
    sessionStorage.setItem("consId", consId);
  }, []);

  return (
    <div className="consultation_inner">
      <div className="desc_area pb-0 account_home ">
        <div className="row">
          <div className="col-xl-6">
            <div className="card mb-4">
              <div className="card-body height-330">
                <div className="consulatation_card">
                  <div className="d-flex justify-content-between align-items-center new_consultation">
                    <h3 className="common_title">Upcoming Consultations</h3>
                    <Link
                      // onClick={() =>
                      //   sessionStorage.setItem(
                      //     "consIdReschedule",
                      //     "undefined"
                      //   )
                      // }
                      to="/new-consultation"
                      className="small_dark_btn small_drk_btn_margin"
                    >
                      New Consultation
                    </Link>
                  </div>
                  <div
                    className={
                      convoDetail?.length > 0
                        ? "overflow_table consultation_table upcoming_consultation_table"
                        : "overflow_table consultation_table scroll-hide"
                    }
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Doctor</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="overflow-y-scroll upcomingConsultation">
                        {convoDetail?.length > 0 ? (
                          convoDetail?.map((item, i) => {
                            return (
                              <tr className="box_prop" key={i}>
                                <td>
                                  <p>{MomentFunc.Date(item?.date)}</p>
                                </td>
                                <td>
                                  <p>{MomentFunc.Time(item?.date)}</p>
                                  {/* <p>
                                    {}
                                  </p> */}
                                </td>
                                <td>
                                  <p>
                                    {item?.assignedDoctor
                                      ? "Dr. " +
                                        item?.assignedDoctor?.firstName.concat(
                                          " ",
                                          item?.assignedDoctor?.lastName
                                        )
                                      : "Not Assigned"}
                                  </p>
                                </td>
                                <td>
                                  {
                                  // Date.now() < moment(item?.date) && new Date() > moment(item?.date) - 10 * 60 * 1000 
                                  Date.now() >= moment(item?.date).valueOf() - 10 * 60 * 1000 && 
                                  Date.now() <= moment(item?.date).valueOf() + 10 * 60 * 1000  ? (
                                    <p>
                                      <Link
                                        onClick={() => {
                                          handleShow2(item);
                                          setJoinCallDetail(item);
                                        }}
                                        to="#"
                                        className="join_call"
                                      >
                                        Join Call
                                      </Link>
                                    </p>
                                  ) : (
                                    <p>
                                      <a
                                        href="#"
                                        className="join_call"
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop"
                                        onClick={() => setConsId(item?._id)}
                                        data-toggle="modal"
                                        data-target="#exampleModal1"
                                      >
                                        Manage Booking
                                      </a>
                                    </p>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <div className="d-flex justify-content-center mt-5">
                            No upcoming consultations yet
                          </div>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card mb-4">
              <div className="card-body height-330 pres_card_body">
                {!viewPres ? (
                  <div className="consulatation_card">
                    <h3 className="common_title">Prescriptions</h3>
                    <div
                      className={
                        pricriptions?.length > 0
                          ? "overflow_table pres_table past_pres table-responsive"
                          : "overflow_table pres_table past_pres table-responsive scroll-hide"
                      }
                    >
                      <table className="table admin-prescription prescription_lg_table">
                        <thead>
                          <tr>
                            <th className="border-b-c">Issue Date</th>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr className="box_prop mb-2"> */}

                          {pricriptions?.length > 0 ? (
                            pricriptions?.map((item, i) => {
                              return (
                                <tr className="box_prop">
                                  <td>
                                    <p>{MomentFunc.Date(item.date)}</p>
                                  </td>
                                  {/* <td className="med-td"> */}
                                  <td className="med-td">
                                    {item?.medication?.map((med) => {
                                      return <p>{med.meds}</p>;
                                    })}
                                  </td>
                                  <td>
                                    <p>
                                      {item?.medication?.map((med) => {
                                        return <p>{med.quantity}</p>;
                                      })}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <a
                                        onClick={() => viewPrescription(item)}
                                        className="view_btn cursor-pointer"
                                      >
                                        View
                                      </a>
                                    </p>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <div className="d-flex justify-content-center mt-5 ">
                              No current prescriptions yet
                            </div>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <ViewPrescription
                    ref={PrescriptionRef}
                    handlePresPrint={handlePresPrint}
                    handleClose={handleClose}
                    viewPresData={presData}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Blogs */}

        <div className="row">
          <div className="col-xl-6 mb-4">
            <div className="card blog_card">
              <div className="card-body">
                <div className="blog_card_inner">
                  <h3 className="common_title">Latest Updates</h3>
                  <div className="overflow_scroll">
                    <ul className="blogs_details blog_scroll userportal_blog">
                      {blogs?.length > 0 ? (
                        blogs?.map((item, i) => {
                          return (
                            <li key={i} className="mb-4">
                              <img
                                src={HOST_NAME + "public/uploads/" + item.img}
                                alt="blog"
                                className="img-fluid user-blog-img"
                              />
                              <div className="blog_inner">
                                <div className="blog_title">
                                  <h4>{item.title}</h4>
                                  <p>{item.author}</p>
                                </div>
                                <div className="read_more_btn latest_updates_btn">
                                  <a
                                    onClick={() =>
                                      navigate(`/read-blog/${item.id}`)
                                    }
                                    className="light_btn"
                                  >
                                    Read More
                                  </a>
                                </div>
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-center mt-5 ">
                          No blogs yet
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="card mb-4">
              <div className="card-body medi_details">
                <div className="med_card_inner">
                  <h3 className="common_title">Medical Details</h3>
                </div>
                <div className="overflow_table prescription_table">
                  <table className="table admin-prescription">
                    <thead>
                      <tr>
                        <th>Medication Name</th>
                        <th>Dosage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr className="box_prop mb-2"> */}

                      {medication?.length > 0 ? (
                        medication?.map((item, i) => {
                          return (
                            <tr key={i} className="box_prop">
                              <td>
                                <p>{item?.med_name}</p>
                              </td>
                              {/* <td className="med-td"> */}
                              <td>
                                <p>{item.dosage ? item.dosage : "--"}</p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <div className="d-flex justify-content-center mt-5 ">
                          No medical details yet
                        </div>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="card questionnaire mb-5">
              <div className="card-body">
                <div className="questionnaire_inner">
                  <a
                    onClick={() => HandleHealthQuestionaire()}
                    className="questionnaire_inner cursor-pointer"
                  >
                    Take Our Health Questionnaire
                    <img src={temperature} alt="temperature" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  Appointment Modal */}

      <Modal size="lg" show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Consent form:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="pop-form-main">
            <h4>
              Patient Name:{" "}
              {joinCallDetail?.user_id?.firstName +
                " " +
                joinCallDetail?.user_id?.lastName}
            </h4>
            <h4>
              Date of Birth:{" "}
              {moment(joinCallDetail?.user_id?.dob).format("DD/MM/YYYY")}
            </h4>
            <h4>Date: {moment(joinCallDetail?.date).format("DD/MM/YYYY")}</h4>

            <p className="mt-4">
              I,{" "}
              {joinCallDetail?.user_id?.firstName +
                " " +
                joinCallDetail?.user_id?.lastName}
              , hereby consent to participate in an online dental consultation
              with ToothAid through the use of telecommunication technologies. I
              understand that the purpose of this consultation is to discuss my
              dental concerns and potential treatment options.
              <br />I acknowledge that while the online consultation allows for
              remote communication, it may not fully replicate an in-person
              dental examination. I understand that the dentist will rely on the
              information provided by me and any images or records submitted
              electronically to assess my dental condition and provide
              recommendations.
            </p>
            <p>
              I am aware of the potential risks and limitations associated with
              an online consultation, including but not limited to:
            </p>
            <ul>
              <li>
                Inability to perform a physical examination, which may affect
                the accuracy of diagnosis.
              </li>
              <li>
                Technical issues or interruptions in the communication
                technology that could disrupt the consultation.
              </li>
              <li>
                Limited ability to provide emergency care or treatment in the
                event of complications.
              </li>
            </ul>
            <p>
              I understand that the information shared during the consultation
              is confidential and will be treated in accordance with applicable
              privacy laws and regulations.
              <br />I acknowledge that the dentist has explained the nature and
              purpose of the online consultation, as well as any alternative
              options available to me.
              <br />I agree to participate in the online dental consultation
              voluntarily, and I understand that I have the right to withdraw my
              consent at any time.
            </p>

            <p>
              By signing below, I certify that I have read and understood the
              contents of this consent form, and I consent to proceed with the
              online dental consultation.
            </p>

            <h5>
              Patient's Signature:{" "}
              {joinCallDetail?.user_id?.firstName +
                " " +
                joinCallDetail?.user_id?.lastName}
            </h5>
            <h5>Date: {moment(joinCallDetail?.date).format("DD/MM/YYYY")}</h5>

            {/* <h5>Dentist's Name (Provider): {joinCallDetail?.doctor_id?.firstName + " " + joinCallDetail?.doctor_id?.lastName}</h5> */}
            <h5>Dentist's Name (Provider): ToothAid</h5>
            <h5>Date: {moment(joinCallDetail?.date).format("DD/MM/YYYY")}</h5>

            <p className="mt-4">
              Please note that this consent form is valid for the specific
              online consultation session indicated above. If further
              consultations are required, a new consent form may be necessary.
            </p>

            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                onChange={(e) => setAgreeTerms(e.target.checked)}
              />
              <label className="form-check-label" for="flexCheckDefault">
                I consent
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="pop-set-btns">
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (!agreeTerms) {
                return toast.info("Please agree to our terms and use.");
              }
              handleClose2();
              setAgreeTerms();
              navigate(`/join-call/${joinCallDetail?._id}`);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="modal fade appointment_modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content ">
            <div className="close-btn">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="modal-btn">
                <button
                  onClick={() => handleUpdate()}
                  data-bs-dismiss="modal"
                  type="button"
                  className="re-schedule-btn"
                >
                  Re-Schedule
                </button>
                <button
                  onClick={() => handleCancel(consId)}
                  data-bs-dismiss="modal"
                  type="button"
                  className="btn btn-outline-danger cancel-btn mt-3"
                >
                  Cancel Appointment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpcomigConsultation;
