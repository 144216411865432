import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Questionnare from "./Questionnare";
import DoctorSelect from "./DoctorSelect";
import { useDispatch, useSelector } from "react-redux";
import { getAdmins } from "../../../Redux/Actions/admin/adminPanel";
import ViewImageModal from "./ViewImageModal";
const ConsNotes = ({ socket, consInfo }) => {
  const IMG = process.env.REACT_APP_HOST_NAME;
  const [notes, setNotes] = useState();
  const [viewModalImg, setViewModalImg] = useState(false);
  const [userNotes, setUserNotes] = useState();
  const [convoId, setConvoId] = useState();
  const [view, setView] = useState("Notes");
  const dispatch = useDispatch();
  const { id } = useParams();
  const admins = useSelector((state) => state.adminPanel.admins);
  const image1 = [IMG + "public/uploads/" + consInfo?.cons?.consNotesImage[0]];
  useEffect(() => {
    socket.on("recv_notes", (notesObject) => {
      setUserNotes(notesObject?.fromUser);
    });
  }, [socket]);

  useEffect(() => {
    dispatch(getAdmins());
  }, []);

  useEffect(() => {
    if (id == consInfo?.cons?._id) {
      const docNotes = consInfo?.cons?.fromDoctor;
      setNotes(docNotes);
      const userNotes = consInfo?.cons?.fromUser;
      setUserNotes(userNotes);
      if (consInfo?.chat?.conversationId) {
        const convo = consInfo?.chat?.conversationId;
        setConvoId(convo);
        (async () => await socket.emit("join_room", convo))();
      }
    }
  }, [id, consInfo]);

  const sendNotes = (e) => {
    if (e.key === "Enter") {
      const notesObject = {
        convoId: convoId,
        consId: consInfo?.cons?._id,
        fromDoctor: notes,
      };
      try {
        socket.emit("add_notes", notesObject);
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <div className="col-lg-5 upcoming_consultation consultationNotes h-700">
        <div className="chat_buttons d-flex tabs_margin justify-content-between">
          <div className="d-flex notes_btn">
            <button
              onClick={() => setView("Notes")}
              className={view == "Notes" ? "cre_new blue_btn " : "white-btn"}
            >
              Notes
            </button>
            <button
              onClick={() => setView("Question")}
              className={view == "Question" ? "cre_new blue_btn" : "white-btn"}
            >
              Questionnaire
            </button>
          </div>
          {admins && <DoctorSelect consInfo={consInfo} admins={admins} />}
        </div>
        {view == "Notes" ? (
          <div className="card">
            <div className="">
              <div className="consulatation_card doctorNotes">
                <div className="upcoming_con_title">
              
                 <div className="image_xray_view">
                 <h3 className="common_title">Consultation Notes</h3>
                    {consInfo?.cons?.consNotesImage && <button onClick={()=>setViewModalImg(true)}>view image</button>}
                 </div>
                  <p>Notes for Doctor</p>
                </div>
          
                <div className="notes consult_notes">
                  {/* <textarea
                  className="form-control"
                  readOnly
                  value={userNotes}
                ></textarea> */}
                  <p className="mb-0">{userNotes}</p>
                </div>
          
              </div>
              <div className="consulatation_card notes_height">
                <div className="upcoming_con_title ">
                  <p>Notes for your patient</p>
                </div>
                <div className="">
                  <div className="notes notes_input">
                    <textarea
                      className="form-control"
                      placeholder="Type Here..."
                      onChange={(e) => setNotes(e.target.value)}
                      value={notes}
                      onKeyDown={sendNotes}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          view == "Question" &&
          consInfo?.medHistory && (
            <Questionnare medHistory={consInfo?.medHistory} />
          )
        )}
      </div>
{
  viewModalImg && <ViewImageModal viewModalImg={viewModalImg}  setViewModalImg={setViewModalImg} image1={image1}/>
}
    
    </>
  );
};

export default ConsNotes;
