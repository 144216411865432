import React from "react";
import Layout from "../components/layout/Layout";
export default function ReportIssue() {
  return (
    <Layout>
      {" "}
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="mb-sm-0 font-size-28">Reported Issues</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="members_tbl">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Reported Issues</h4>
                        </div>
                        <div className="table-responsive table-defaut-design dealer-table-c">
                          <table
                            id="datatable"
                            className="table  vehicles_table  w-100"
                          >
                            <thead>
                              <tr>
                                <th>Ticket Id</th>
                                <th>Reported</th>
                                <th>Reported By</th>
                                <th>Reported Against</th>
                                <th>Message</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody className="td_color trans_table_fix_height">
                              <tr>
                                <td>000001</td>
                                <td>01/02/2023 12:30PM</td>
                                <td>Johnsmith</td>
                                <td>Meawe</td>
                                <td>I am trying to del...</td>
                                <td>Open</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
