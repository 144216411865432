import React, { useState } from "react";
import logoMain from "../../assets/images/logo-1.svg";
import expand_right from "../../assets/images/icons/expand_right.svg";
import { Link, useNavigate } from "react-router-dom";
import LayoutHome from "../Layout/LayoutHome";
import { NavLink } from "react-router-dom";
import TrustPilot from "./homepage/TrustPilot";
import { useFormik } from "formik";
import { addBecomeDoctor } from "../../Redux/Actions/user/userAll";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export default function BecomeDentist() {
  const dispatch = useDispatch();
  const [slide, setSlide] = useState(0);
  const navigate = useNavigate();
  const handleDentistClick = () => {
    if (sessionStorage.getItem("token")) {
      navigate("/symptom-checker-start");
    } else {
      navigate("/userlogin");
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "Enter first name";
    }
    if (!values.lastName) {
      errors.lastName = "Enter last name";
    }
    if (!values.email) {
      errors.email = "Enter email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Enter correct email";
    }
    if (!values.dob) {
      errors.dob = "Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dob: "",
      email: "",
      phnNumber: "",
      houseNo: "",
      street: "",
      townCity: "",
      postCode: "",
      uni_attended: "",
      highest_qualification: "",
      year_of_grad: "",
      gdc_no: "",
      dbs_no: "",
      indemnity_provider: "",
      indemnity_number: "",
    },

    validate,
    //  validateOnChange:validateValue,
    onSubmit: async (values) => {
      // setValidateValue(true)

      try {
        dispatch(addBecomeDoctor(values)).then((res) => {
          if (res?.payload?.success) {
            toast.success(res?.payload?.message);
            navigate("/");
          } else {
            toast.info(res?.payload?.error);
          }
        });
      } catch (error) {
        console.log(error, "error");
      }
    },
  });

  return (
    <LayoutHome becomeMember={false}>
      <div id="wrapper">
        <section className="PreventionEeducation">
          <div className="container-fluid container_inner_width">
            <div className="banner-content ">
              <h1 className="mb-0">Become a ToothAid Dentist</h1>
              <h2 className="mt-3">Join the team to help patients online</h2>
            </div>
          </div>
        </section>
        {/* <!-- banner  end -->


        <!-- common banner bottom part start --> */}
        <section className="trust_wrapper position-relative">
          <div className="banner_btm">
            <div className="btm_logo">
              {/* <img src={logoMain} alt="bottom-logo" className="img-fluid" /> */}
            </div>
            <TrustPilot />
            <div className="btm_ques">
              <p
                onClick={() => handleDentistClick()}
                className="book_dentist_link"
              >
                Having issues? Book in online
                <img src={expand_right} alt="right-arrow" />
              </p>
            </div>
          </div>
        </section>
        {/* <!-- common banner bottom part end -->

        <!--Reason for Teeth Problem Start--> */}
        <section className="section-divide">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center">
                <div className="become-member-part">
                  <form onSubmit={formik.handleSubmit} className="w-100">
                    <div className="become-denstist-form">
                      <div className="">
                        <div className="title_signin become-text-center">
                          <h1 aria-label="registerPage ">
                            Become a ToothAid Dentist
                          </h1>
                          <p>
                            Submit your details today and we’ll reach out if you
                            are successful.
                          </p>
                          {/* <div className="form-group mb-2 mt-4 mb-4">
                            <input
                              name="fullname"
                              type="text"
                              className="form-control"
                              placeholder="Full Name"
                              onChange={formik.handleChange}
                              value={formik.values.fullname}
                            />
                            {formik.errors.fullname ? (
                              <div className="formik-errors">
                                {formik.errors.fullname}
                              </div>
                            ) : null}
                          </div> */}

                           <div className="form-group mb-2 mt-4 mb-4">
                            <input
                              name="firstName"
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              onChange={formik.handleChange}
                              value={formik.values.firstName}
                            />
                            {formik.errors.firstName ? (
                              <div className="formik-errors">
                                {formik.errors.firstName}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group mb-2 mt-4 mb-4">
                            <input
                              name="lastName"
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                            />
                            {formik.errors.lastName ? (
                              <div className="formik-errors">
                                {formik.errors.lastName}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group mb-4">
                            <input
                              name="dob"
                              type="date"
                              className="form-control"
                              placeholder="DOB"
                              onChange={formik.handleChange}
                              value={formik.values.dob}
                            />
                            {formik.errors.dob ? (
                              <div className="formik-errors">
                                {formik.errors.dob}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group mb-4">
                            <input
                              name="email"
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              onChange={formik.handleChange}
                              value={formik.values.email}
                            />
                            {formik.errors.email ? (
                              <div className="formik-errors">
                                {formik.errors.email}
                              </div>
                            ) : null}
                          </div>
                          <div className="form-group mb-4">
                            <input
                              name="phnNumber"
                              type="number"
                              className="form-control"
                              placeholder="Phone"
                              onChange={formik.handleChange}
                              value={formik.values.phnNumber}
                            />
                            {formik.errors.phnNumber ? (
                              <div className="formik-errors">
                                {formik.errors.phnNumber}
                              </div>
                            ) : null}
                          </div>

                          <div className="form-group mb-2">
                            <input
                              name="houseNo"
                              type="text"
                              className="form-control"
                              placeholder="First Line of Address"
                              onChange={formik.handleChange}
                              value={formik.values.houseNo}
                            />
                          </div>

                          <div className="form-group mb-2">
                            <input
                              name="street"
                              type="text"
                              className="form-control"
                              placeholder="Second Line of Address (Optional)"
                              onChange={formik.handleChange}
                              value={formik.values.street}
                            />
                          </div>
                          <div className="form-group mb-2">
                            <input
                              name="townCity"
                              type="text"
                              className="form-control"
                              placeholder="Town/City"
                              onChange={formik.handleChange}
                              value={formik.values.townCity}
                            />
                          </div>
                          <div className="form-group mb-4">
                            <input
                              name="postCode"
                              type="text"
                              className="form-control"
                              placeholder="Post Code"
                              onChange={formik.handleChange}
                              value={formik.values.postCode}
                            />
                          </div>
                          <div className="form-group mb-2">
                            <input
                              name="uni_attended"
                              type="text"
                              className="form-control"
                              placeholder="University Attended"
                              onChange={formik.handleChange}
                              value={formik.values.uni_attended}
                            />
                          </div>
                          <div className="form-group mb-2">
                            <input
                              name="highest_qualification"
                              type="text"
                              className="form-control"
                              placeholder="Highest Qualification Level"
                              onChange={formik.handleChange}
                              value={formik.values.highest_qualification}
                            />
                          </div>
                          <div className="form-group mb-2">
                            <input
                              name="year_of_grad"
                              type="number"
                              className="form-control"
                              placeholder="Year of Graduation"
                              onChange={formik.handleChange}
                              value={formik.values.year_of_grad}
                            />
                          </div>
                          <div className="form-group mb-2">
                            <input
                              name="gdc_no"
                              type="number"
                              className="form-control"
                              placeholder="GDC Number"
                              onChange={formik.handleChange}
                              value={formik.values.gdc_no}
                            />
                          </div>
                          <div className="form-group mb-4">
                            <input
                              name="dbs_no"
                              type="number"
                              className="form-control"
                              placeholder="DBS Number"
                              onChange={formik.handleChange}
                              value={formik.values.dbs_no}
                            />
                          </div>

                          <div className="form-group mb-2">
                            <input
                              name="indemnity_provider"
                              type="text"
                              className="form-control"
                              placeholder="Indemnity Provider"
                              onChange={formik.handleChange}
                              value={formik.values.indemnity_provider}
                            />
                          </div>
                          <div className="form-group mb-2">
                            <input
                              name="indemnity_number"
                              type="number"
                              className="form-control"
                              placeholder="Indemnity Number"
                              onChange={formik.handleChange}
                              value={formik.values.indemnity_number}
                            />
                          </div>

                          <div className="btn_submit mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-custom btn-lg w-100"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!--Reason for Teeth Problem / End-->

        <!-- Testimonial start --> */}
      </div>
    </LayoutHome>
  );
}
