import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { createAdmin } from "../../../Redux/Actions/admin/adminPanel";
import { useDispatch } from "react-redux";

const CreateAdminModal = (props) => {
  const dispatch = useDispatch();
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    if (!values.firstName) {
      errors.firstName = "Required";
    }
    if (!values.lastName) {
      errors.lastName = "Required";
    }
    if (!values.phnNumber) {
      errors.phnNumber = "Required";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phnNumber: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        dispatch(createAdmin(values)).then(props.onHide());
        formik.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="admin-popup"
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Create Admin
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive table-defaut-design">
            <div className="form-group mb-3">
              <label htmlFor="">First Name</label>
              <input
                className="form-control"
                onChange={formik.handleChange}
                placeholder="First Name"
                name="firstName"
                value={formik.values.firstName}
              />
              {formik.errors.firstName ? (
                <p className="formik-errors">{formik.errors.firstName}*</p>
              ) : null}
            </div>

            <div className="form-group mb-3">
              <label htmlFor="">Surname</label>
              <input
                className="form-control"
                onChange={formik.handleChange}
                placeholder="Surname"
                name="lastName"
                value={formik.values.lastName}
              />
              {formik.errors.lastName ? (
                <p className="formik-errors">{formik.errors.lastName}*</p>
              ) : null}
            </div>

            <div className="form-group mb-3">
              <label htmlFor="">Email Address</label>
              <input
                className="form-control"
                onChange={formik.handleChange}
                placeholder="Email Address"
                name="email"
                value={formik.values.email}
              />
              {formik.errors.email ? (
                <p className="formik-errors">{formik.errors.email}*</p>
              ) : null}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="">Phone Number</label>
              <div className="d-flex align-items-center phn-form-control">
                <div className="country_code">
                  <p>+44</p>
                </div>
              <div className="phn-form-control_width">
              <input
                  type="number"
                  className="form-control "
                  onChange={formik.handleChange}
                  placeholder=""
                  name="phnNumber"
                  value={formik.values.phnNumber}
                />
              </div>
             
              </div>
             
              {formik.errors.phnNumber ? (
                <p className="formik-errors">{formik.errors.phnNumber}*</p>
              ) : null}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="btn_submit ">
            <button
              type="submit"
              className="btn btn-primary btn-custom btn-lg w-100 submit_btn confirmation_btn"
            >
              Submit
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateAdminModal;
