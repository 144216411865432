import React from "react";
import { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
const FirstRow = ({ usersDashboard }) => {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const socketRef = useRef();
  const [onlineUsers, setOnlineUsers] = useState();
  useEffect(() => {
    socketRef.current = io.connect(BASE_URL);
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.on("get-users", (users) => {
        setOnlineUsers(users);
      });
    }
  }, [socketRef.current]);

  return (
    <div className="row">
      <div className="col-md-9">
        <div className="card">
          <div className="card-body h160">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
              <h4 className="title_text"> Overview</h4>

              <span>
                <div className="dropdown_custom ">
                  <div className="dropdown">
                    <button className="p-0 btn-custom-drop dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                      Lifetime <i className="mdi mdi-menu-down"></i>
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{margin: "0px"}}>
                      <a className="dropdown-item" href="#">  Lifetime</a>
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="bglight dasb_text">
                  <h2>{usersDashboard?.totalUsers}</h2>
                  <p className="dasb_text_p">Total Users </p>
                  <p className="dasb_textlastp">
                    {usersDashboard?.usersPerMonth} New Users This Month
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="bgbrown dasb_text">
                  <h2>
                    {usersDashboard?.totalCons?.length > 0
                      ? usersDashboard?.totalCons?.length
                      : "0"}
                  </h2>
                  <p className="dasb_text_p">Consultations </p>
                  <p className="dasb_textlastp">
                    {usersDashboard?.consPerMonth} New Booking This Month
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="bgblue dasb_text">
                  <h2>90</h2>
                  <p className="dasb_text_p">Practice Refferals</p>
                  <p className="dasb_textlastp">0% increase from last month</p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="bgblue dasb_text">
                  <h2>90</h2>
                  <p className="dasb_text_p">Paid Advertisement Income</p>
                  <p className="dasb_textlastp">0% increase from last month</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card">
          <div className="card-body h160">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
              <h4 className="title_text">Live Users</h4>
              {/* <span>
                <div className="dropdown_custom ">
                  <div className="dropdown">
                    <button
                      className="p-0 btn-custom-drop dropdown-toggle "
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Lifetime <i className="mdi mdi-menu-down"></i>
                    </button>
                    <div
                      className="dropdown-menu chart_dropdown"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Lifetime
                      </a>
                    </div>
                  </div>
                </div>
              </span> */}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="bg_dasg_ble bgblue dasb_text">
                  <h2>
                    {onlineUsers && onlineUsers?.length > 0
                      ? onlineUsers?.length
                      : "0"}
                  </h2>
                  <p className="mb-2">
                    Total <br />
                    &nbsp;
                  </p>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="bg_dasg_ble bgyelow dasb_text">
                  <h2>12,123</h2>
                  <p className="mb-2">
                    Total <br />
                    &nbsp;
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstRow;
