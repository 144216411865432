import React, { useEffect, useState, useRef } from "react";
import Layout from "../layout/Layout";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import backBtn from "../../../assets/images/icons/back_btn.svg";
import { useFormik } from "formik";
import ViewPrescriptions from "./ViewPrescriptions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createPrescription,
  getMemberDetails,
  getPrescriptions,
} from "../../../Redux/Actions/admin/adminPanel";
import { useReactToPrint } from "react-to-print";
import {
  toastError,
  toastInfo,
} from "../../../Redux/Reducers/admin/adminPanelSlice";
import MomentFunc from "../../../utils/MomentDateTime";
import { medicationArr } from "./medication_list";
import moment from "moment";

const NewPrescriptions = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const navigate = useNavigate();
  const id = useParams().id;
  const memberDetails = useSelector((state) => state.adminPanel.memberDetails);
  const prescriptions = useSelector((state) => state.adminPanel.prescriptions);
  const dispatch = useDispatch();
  const [viewPres, setViewPres] = useState(false);
  const [slectedMed, setSlectedMed] = useState();
  const [slectedMed2, setSlectedMed2] = useState();
  const [viewPresData, setViewPresData] = useState(null);
  const [medication, setMedication] = useState([
    {
      meds: "",
      quantity: "",
    },
  ]);
  const [supply, setSupply] = useState("");
  const [medsArray, setMedsArray] = useState([]);
  var today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    dispatch(getMemberDetails(id));
    dispatch(getPrescriptions(id));
  }, []);

  const handleSelectBox = (e) => {
    const data = medicationArr?.filter((item) => item.Medication == e)[0];
    setSupply(data?.Supply);
    setSlectedMed(e);
  };

  const handleMedsSubmit = () => {
    console.log("dsddddddddddddddddddddd");
    setSlectedMed2("");
    setSupply("");
    if (slectedMed) {
      const data = medicationArr?.filter(
        (item) => item.Medication == slectedMed
      )[0];
      if (medsArray?.length >= 4) {
        toastError("Limit Reached");
      } else {
        setMedsArray((prevVal) => {
          return [
            ...prevVal,
            {
              meds: data?.Medication,
              quantity: data?.Supply,
            },
          ];
        });
        setMedication({
          meds: "",
          quantity: "",
        });
      }
    }

    
  };

  const validate = (values) => {
    const errors = {};
    if (!values.date) {
      errors.date = "Required";
    }
    if (!values.notes) {
      errors.notes = "Required";
    }
    return errors;
  };

  const initialValues = {
    date: "",
    notes: "",
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async (values) => {
      values.id = id;

      if (medsArray?.length < 1) {
        toastInfo("Please add medications");
      }
      // const date = moment(values.date, "YYYY-MM-DD").toDate();

      if (medsArray?.length > 0) {
        try {
          values.medication = medsArray;
          dispatch(createPrescription(values));
          formik.resetForm();
          setMedsArray([]);
        } catch (error) {
          console.log(error);
        }
      }
      // else {
      //   try {
      //     values.medication = [medication];
      //     await dispatch(createPrescription(values));
      //     formik.resetForm();
      //     setMedication({
      //       meds: "",
      //       quantity: "",
      //     });
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }
    },
  });

  const handleBack = () => {
    if (!viewPres) {
      navigate(-1);
    } else {
      setViewPres(false);
    }
  };

  const handleViewPres = (pres) => {
    setViewPres(true);
    setViewPresData(pres);
  };

  const removeMed = (index) => {
    setMedsArray((prev) => {
      return prev.filter((item, i) => i != index);
    });
  };

  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash ">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <span className="small_text">Members</span>
                      <h4 className="mb-sm-0 font-size-28">New Prescription</h4>
                      <div className="d-flex mt-2 sub_head">
                        <button
                          className="btn back_button"
                          onClick={handleBack}
                        >
                          <img src={backBtn} />
                        </button>
                        {memberDetails && (
                          <h4 className="sub-heading-text-header ms-2">
                            {memberDetails.fullName}
                          </h4>
                        )}
                      </div>
                    </div>
                    <div className="page-title-right">
                      {/* <!-- App Search--> */}
                      <form className="app-search d-none d-lg-block ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span className="bx bx-search"></span>
                          </div>
                          <img
                            src={admin_avatar_header}
                            className="avata_serch"
                            alt=""
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-6">
                  <div className="tbl_user_info h-100">
                    <div className="card h-100">
                      <div className="d-flex justify-content-between align-items-center flex-wrap mb-0 position-relative border-b-c ">
                        <h3 className="common_title">
                          Create New Prescription
                        </h3>
                      </div>
                      <div className="card-body new_pres">
                        <form onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group mb-3">
                                    <label className="view-label-input">
                                      Date
                                    </label>
                                    <input
                                      onChange={formik.handleChange}
                                      type="date"
                                      name="date"
                                      placeholder="01/01/2023"
                                      className="view-label-input form-control "
                                      value={formik.values.date}
                                      min={today}
                                    />
                                    {formik.errors.date && (
                                      <p className="formik-errors">
                                        {formik.errors.date}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="form-group mb-3">
                                    <label className="view-label-input">
                                      Medication
                                    </label>
                                    <select
                                      onChange={(e) =>
                                        {
                                          handleSelectBox(e.target.value);
                                          setSlectedMed2(e.target.value)
                                        }
                                      }
                                      value={slectedMed2}
                                      name="meds"
                                      className="view-label-input form-control select_input"
                                    >
                                      <option>Select</option>
                                      {medicationArr?.map((item) => {
                                        return (
                                          <>
                                            <option value={item?.Medication}>
                                              {item?.Medication}{" "}
                                            </option>
                                          </>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group mb-3">
                                    <label className="view-label-input">
                                      Quantity
                                    </label>
                                    <input
                                      //  onChange={handleMedsChange}
                                      // type="number"
                                      name="quantity"
                                      // placeholder="Type here"
                                      className="view-label-input form-control"
                                      value={supply}
                                      disabled
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div
                                    onClick={handleMedsSubmit}
                                    className="add-cirle-p cursor-pointer"
                                  >
                                    <span>+</span>
                                  </div>
                                  {medsArray?.length > 0 ? (
                                    <div className="row">
                                      <div className="col-md-6 ">
                                        <label className="view-label-input">
                                          Selected Medication
                                        </label>
                                      </div>
                                      <div className="col-md-6  ">
                                        <label className="view-label-input">
                                          Selected Quantity
                                        </label>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {medsArray?.length > 0
                                  ? medsArray.map((meds, i) => {
                                      return (
                                        <>
                                          <div className="col-md-6 mb-3 ">
                                            <div className="output-view  fix_input_height">
                                              {meds?.meds}
                                            </div>
                                          </div>
                                          <div className="col-md-5 mb-3 ">
                                            <div className="output-view  fix_input_height">
                                              {meds?.quantity}
                                            </div>
                                          </div>
                                          <div className="col-md-1 mb-3 ">
                                            <div
                                              onClick={() => removeMed(i)}
                                              className="add-cirle-p cursor-pointer d-flex align-items-center justify-content-end h-100"
                                            >
                                              <span>-</span>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    })
                                  : null}

                                <div className="col-md-12">
                                  <div className="form-group mb-3 mt-4 prescripion_notes">
                                    <label className="view-label-input">
                                      Add Instructions / Dose Field
                                    </label>
                                    <textarea
                                      onChange={formik.handleChange}
                                      name="notes"
                                      style={{ resize: "none" }}
                                      className="form-control"
                                      placeholder="Type here..."
                                      value={formik.values.notes}
                                    ></textarea>
                                    {formik.errors.notes && (
                                      <p className="formik-errors">
                                        {formik.errors.notes}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-12">
                              <div className="create_new_btn">
                                <button type="submit" className="btn cre_new">
                                  Create
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {prescriptions && !viewPres ? (
                  <div className="col-xl-6">
                    <div className="previous_pres">
                      <div className="card mb-4">
                        <div className="pres_card_body card-body">
                        <div className="consulatation_card">
                          <h3 className="common_title pb-0">Prescriptions</h3>
                          <div className="overflow_table pres_table">
                            <table className="table admin-prescription prescription_lg_table">
                              <thead>
                                <tr>
                                  <th className="border-b-c">Issue Date</th>
                                  <th>Item(s)</th>
                                  <th>Quantity</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {prescriptions?.length > 0 ? (
                                  prescriptions?.map((prescription, index) => {
                                    return (
                                      <tr key={index} className="box_prop mb-2">
                                        <td>
                                          <p>
                                            {MomentFunc.Date(
                                              prescription?.date
                                            )}
                                          </p>
                                        </td>
                                        <td className="med-td">
                                          {prescription?.medication?.map(
                                            (med) => {
                                              return <p>{med.meds}</p>;
                                            }
                                          )}
                                        </td>
                                        <td>
                                          <p>
                                          {prescription?.medication?.map(
                                            (med) => {
                                              return <p>{med.quantity}</p>;
                                            }
                                          )}
                                          </p>
                                        </td>
                                        <td>
                                          <p>
                                            <Link
                                              onClick={() =>
                                                handleViewPres(prescription)
                                              }
                                              className="view_btn"
                                            >
                                              View
                                            </Link>
                                          </p>
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={3}
                                      valign="middle"
                                      className="h-370"
                                    >
                                      <p className="no_content_table">
                                        No Prescriptions Yet
                                      </p>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  viewPresData && (
                    <ViewPrescriptions
                      ref={componentRef}
                      viewPresData={viewPresData}
                      handlePrint={handlePrint}
                      handleBack={handleBack}
                    />
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NewPrescriptions;
