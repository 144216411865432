import React, { useEffect, useState } from "react";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import Layout from "../layout/Layout";
import AreaChart from "./AreaChart";
import { useDispatch, useSelector } from "react-redux";
import { getDentist, getTransactions } from "../../../Redux/Actions/admin/adminPanel";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";
import moment from "moment";

export default function Transactions() {
  const transactions = useSelector((state) => state.adminPanel.transactions);
  const totalIncome = useSelector((state) => state.adminPanel.totalIncome);
  const dentists = useSelector((state) => state.adminPanel.dentists);

  const incomePerMonth = useSelector(
    (state) => state.adminPanel.incomePerMonth
  );
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    search: undefined,
    date: undefined,
    assigned_to: undefined,
    dentistId: sessionStorage.getItem("role") == 'dentist' ? sessionStorage.getItem("id") : undefined
  })

  useEffect(() => {
    
    dispatch(getDentist({ filter: filter }))
    dispatch(startstopLoading(true));
    dispatch(getTransactions({ filter: filter })).then((res) => {
      if (res.payload.success == true || res.payload.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, []);

  const handleFilter = (e) => {
    let { value, name } = e.target;
    setFilter({ ...filter, [name]: value })
    dispatch(getTransactions({ filter: { ...filter, [name]: value } }));
  }

  // let arr=[2,3,4,5,6,7];
  // console.log(arr.slice(1,3),"slice----slice");


  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              {/* <!-- start page title --> */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <span className="small_text">Transactions</span>
                      <h4 className="mb-sm-0 font-size-28">
                        Transactions
                        <span className="header-title-text">
                          {
                            transactions?.length
                          }
                        </span>
                      </h4>
                    </div>
                    <div className="page-title-right">
                      <form className="app-search d-none d-lg-block ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span className="bx bx-search"></span>
                          </div>
                          <img
                            src={admin_avatar_header}
                            className="avata_serch"
                            alt=""
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                  <input
                    name="search"
                    type="text"
                    class="form-control filter-input-element"
                    placeholder="Search Here"
                    onChange={(e) => handleFilter(e)}
                    value={filter.search}
                  />
                </div>
                <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                  <div class="form-group">
                    <input
                      name="date"
                      type="date"
                      class="form-control filter-input-element"
                      id=""
                      placeholder="Search Here"
                      onChange={(e) => handleFilter(e)}
                    />
                  </div>
                </div>
                {sessionStorage.getItem("role") != "dentist" &&
                  <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                    <div class="form-group">

                      <select
                        name="assigned_to"
                        // value={item?.assignedDoctor}
                        onChange={(e) => handleFilter(e)}
                        class="form-control filter-select-element"
                      //disabled={true}
                      >
                        <option value={""}>Assigned To</option>
                        {
                          dentists?.map((item) => {
                            return (
                              <>
                                <option value={item?._id}>{item?.firstName + " " + item?.lastName}</option>
                              </>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>
                }
              </div>
              <div className="row">
                <div className="col-xl-8">
                  <div className="members_tbl">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">All Transactions</h4>
                        </div>
                        <div className="table-responsive table-defaut-design transaction-table-height">
                          <table
                            id="datatable"
                            className="table  vehicles_table  w-100"
                          >
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Dentist</th>
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Method</th>
                              </tr>
                            </thead>
                            <tbody className="td_color trans_table_fix_height">
                              {transactions?.length > 0 ? (
                                transactions?.map((trans) => {
                                  return (

                                    <tr valign="middle">
                                      <td>
                                        {moment(trans?.createdAt).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {moment(trans?.createdAt).format(
                                          "hh:mm a"
                                        )}
                                      </td>
                                      <td>
                                        {trans?.assignedDoctor?.firstName ?
                                          trans?.assignedDoctor?.firstName + " " + trans?.assignedDoctor?.lastName
                                          : "-"
                                        }
                                      </td>
                                      <td>
                                        {trans?.user_id?.firstName.concat(
                                          " ",
                                          trans?.user_id?.lastName
                                        )}
                                      </td>
                                      <td>£ { sessionStorage.getItem('role') == 'dentist' ? (trans?.price*0.9) : parseFloat(trans?.price*0.1).toFixed(2)}</td>
                                      <td>Debit Card</td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    colSpan={5}
                                    valign={"middle"}
                                    className="h-250"
                                  >
                                    <p className="no_content_table">
                                      No Transactions yet
                                    </p>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="garph_2">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Statistics</h4>
                        </div>
                        {/* <div className="days_moth">
                          <ul className="nav nav-pills">
                            <li className="nav-item">
                              <a className="nav-link" href="#">
                                Day
                              </a>
                            </li>
                            <li className="nav-item">
                              <a className="nav-link" href="#">
                                Week
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link active green-btn-medium-c"
                                href="#"
                              >
                                Month
                              </a>
                            </li>
                          </ul>
                        </div> */}
                        <div className="tot_trd_vech pt-3">
                          <p className="mb-2">Total Amount Raised</p>
                          <h5>
                            <b>£{sessionStorage.getItem("role") == 'dentist' ? 0.9*totalIncome : parseFloat(0.1*totalIncome).toFixed(2)}</b>
                          </h5>
                        </div>

                        <div
                          id="spline_area1"
                          className="apex-charts"
                          dir="ltr"
                        >
                          <AreaChart
                            monthArr={incomePerMonth?.map((ob) => ob.month)}
                            dataArr={incomePerMonth?.map((ob) => 
                              sessionStorage.getItem("role") == 'dentist' ? ob.income*0.9 : parseFloat(0.1*ob.income).toFixed(2))}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- table row end --> */}
            </div>{" "}
            {/* <!-- container fluid --> */}
          </div>{" "}
          {/* <!-- End Page-content --> */}
        </div>
        {/* <!-- end main content--> */}
      </div>
    </Layout>
  );
}
