import React, { useEffect } from "react";
import Layout from "../layout/Layout";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvailability,
  sendAvailability,
} from "../../../Redux/Actions/admin/adminPanel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const SetAvailability = () => {
  var today = new Date().toISOString().split("T")[0];
  const regularHoursArray = [
    {
      day: "Monday",
      morningStart: "08:00 am",
      morningEnd: "11:45 am",
      eveningStart: "12:00 pm",
      eveningEnd: "8:00 pm",
      status: "Open",
    },
    {
      day: "Tuesday",
      morningStart: "08:00 am",
      morningEnd: "11:45 am",
      eveningStart: "12:00 pm",
      eveningEnd: "8:00 pm",
      status: "Open",
    },
    {
      day: "Wednesday",
      morningStart: "08:00 am",
      morningEnd: "11:45 am",
      eveningStart: "12:00 pm",
      eveningEnd: "8:00 pm",
      status: "Open",
    },
    {
      day: "Thursday",
      morningStart: "08:00 am",
      morningEnd: "11:45 am",
      eveningStart: "12:00 pm",
      eveningEnd: "8:00 pm",
      status: "Open",
    },
    {
      day: "Friday",
      morningStart: "08:00 am",
      morningEnd: "11:45 am",
      eveningStart: "12:00 pm",
      eveningEnd: "8:00 pm",
      status: "Open",
    },
    {
      day: "Saturday",
      morningStart: "08:00 am",
      morningEnd: "11:45 am",
      eveningStart: "12:00 pm",
      eveningEnd: "8:00 pm",
      status: "Open",
    },
    {
      day: "Sunday",
      morningStart: "08:00 am",
      morningEnd: "11:45 am",
      eveningStart: "12:00 pm",
      eveningEnd: "8:00 pm",
      status: "Closed",
    },
  ];

  const spDayObject = {
    date: "",
    morningStart: "08:00 am",
    morningEnd: "11:45 am",
    eveningStart: "12:00 pm",
    eveningEnd: "8:00 pm",
    status: "Open",
  };

  const [regularHours, setRegularHours] = useState();
  const [specialDaysArray, setSpecialDaysArray] = useState();
  const dispatch = useDispatch();
  const availability = useSelector((state) => state.adminPanel.availability);
  const sendAvail = () => {
    const spDays = specialDaysArray
      .slice(1, specialDaysArray.length)
      .filter((object) => object.date != "");
    const newArr =
      specialDaysArray && specialDaysArray[0]?.date != ""
        ? [specialDaysArray[0], ...spDays]
        : spDayObject;
    // console.log(regularHours);
    dispatch(
      sendAvailability({
        regular: regularHours,
        special: newArr,
      })
    );
  };

  useEffect(() => {
    dispatch(getAvailability());
  }, []);
  //check this in the morning
  useEffect(() => {
    if (availability) {
      setRegularHours(availability?.regular);
      setSpecialDaysArray(availability?.special);
    } else if (!availability) {
      setRegularHours(regularHoursArray);
      setSpecialDaysArray([spDayObject]);
    }
    // if (availability && availability?.regular) {

    // }
    // if (availability && ) {

    // } else if (
    //   !availability &&
    //   !availability?.regular &&
    //   !availability?.special
    // ) {

    // }
  }, [availability]); 
  const handleChange = (e, name, day) => {
    // var value = e.toISOString().split("T")[1];
    const value = moment(e).format("hh:mm a");
    // const { name, value } = e.target;
    setRegularHours((prev) => {
      const editedArray = prev.map((dayObject) => {
        return dayObject.day == day
          ? { ...dayObject, [name]: value }
          : dayObject;
      });
      return editedArray;
    });
  };

  const handleSelectChange = (e, day) => {
    setRegularHours((prev) => {
      const editedArray = prev.map((dayObject) => {
        return dayObject.day == day
          ? { ...dayObject, status: e.target.value }
          : dayObject;
      });
      return editedArray;
    });
  };

  const handleDateSelectChange = (e, date) => {
    setSpecialDaysArray((prev) => {
      const editedArray = prev?.map((dateObject) => {
        return dateObject.date == date
          ? { ...dateObject, status: e.target.value }
          : dateObject;
      });
      return editedArray;
    });
  };

  const handleSpecialChange = (e, date) => {
    const { name, value } = e.target;
    setSpecialDaysArray((prev) => {
      const editedArray = prev.map((dateObject) => {
        return dateObject?.date == date
          ? { ...dateObject, [name]: value  }
          : dateObject;
      });
      return editedArray;
    });
  };

  const handleSpecialTimeChange = (e, name, date) => {
    var value = moment(e).format("hh:mm a");

    setSpecialDaysArray((prev) => {
      const editedArray = prev.map((dateObject) => {
        return dateObject.date == date
          ? { ...dateObject, [name]: value }
          : dateObject;
      });
      return editedArray;
    });
  };

  const handleAddDays = () => {
    if (specialDaysArray[specialDaysArray?.length - 1].date != "") {
      setSpecialDaysArray((prev) => [...prev, spDayObject]);
    }
  };

  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <span className="small_text">Calendar</span>
                      <h4 className="mb-sm-0 font-size-28">
                        Set Availability
                        <span className="header-title-text"></span>
                      </h4>
                    </div>
                    <div className="page-title-right">
                      {/* <!-- App Search--> */}
                      <form className="app-search d-none d-lg-block ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span className="bx bx-search"></span>
                          </div>
                          <img className="avata_serch" alt="" />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {/* Table row starts */}
              <div className="row regular_hours">
                <div className="col-xl-12">
                  <div className="members_tbl">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Regular Hours</h4>{" "}
                          <button
                            onClick={sendAvail}
                            className="calendar_update_button btn cre_new"
                          >
                            Update
                          </button>
                        </div>
                        <form>
                          <div className=" table-defaut-design">
                            <table
                              id="datatable"
                              className="table dt-responsive dealers_table nowrap w-100 table_width"
                            >
                              <thead>
                                <tr>
                                  <th>Day</th>
                                  <th>Open/Closed</th>
                                  <th>Morning Start</th>
                                  <th>Morning End</th>
                                  <th>Evening Start</th>
                                  <th>Evening End</th>
                                </tr>
                              </thead>
                              <tbody className="td_color td_blue">
                                {regularHours?.map((dayRow, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{dayRow.day}</td>
                                      <td>
                                        <select
                                          onChange={(e) =>
                                            handleSelectChange(e, dayRow.day)
                                          }
                                          className="form_control_new form_control_new"
                                        >
                                          <option
                                            value={dayRow.status}
                                            selected
                                            hidden
                                          >
                                            {dayRow.status}
                                          </option>
                                          <option value="Closed">Closed</option>
                                          <option value="Open">Open</option>
                                        </select>
                                      </td>
                                      <td>
                                        {/* <input
                                          type="time"
                                          className={
                                            dayRow.morningStart
                                              ? "form_control_new closed_time"
                                              : "form_control_new open_time"
                                          }
                                          name="morningStart"
                                          value={dayRow.morningStart}
                                          onChange={(e) =>
                                            handleChange(e, dayRow.day)
                                          }
                                        /> */}
                                        <DatePicker
                                          selected={
                                            new Date(
                                              today.concat(
                                                " ",
                                                dayRow.morningStart
                                              )
                                            )
                                          }
                                          onChange={(e) =>
                                            handleChange(
                                              e,
                                              "morningStart",
                                              dayRow.day
                                            )
                                          }
                                          className={
                                            dayRow.status == "Closed"
                                              ? "closed_time"
                                              : "open_time"
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeCaption="Time"
                                          timeIntervals={15}
                                          dateFormat="h:mm aa"
                                          minTime={
                                            new Date(
                                              today.concat(" ", "12:00 am")
                                            )
                                          }
                                          maxTime={
                                            new Date(
                                              today.concat(" ", "11:45 am")
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <DatePicker
                                          selected={
                                            new Date(
                                              today.concat(
                                                " ",
                                                dayRow.morningEnd
                                              )
                                            )
                                          }
                                          onChange={(e) =>
                                            handleChange(
                                              e,
                                              "morningEnd",
                                              dayRow.day
                                            )
                                          }
                                          className={
                                            dayRow.status == "Closed"
                                              ? "closed_time"
                                              : "open_time"
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeCaption="Time"
                                          timeIntervals={15}
                                          dateFormat="h:mm aa"
                                          minTime={
                                            new Date(
                                              today.concat(" ", "12:00 am")
                                            )
                                          }
                                          maxTime={
                                            new Date(
                                              today.concat(" ", "11:45 am")
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                        {/* <input
                                          type="time"
                                          className={
                                            dayRow.morningEnd
                                              ? "form_control_new closed_time"
                                              : "form_control_new open_time"
                                          }
                                          name="morningEnd"
                                          value={dayRow.morningEnd}
                                          onChange={(e) =>
                                            handleChange(e, dayRow.day)
                                          }
                                        /> */}
                                      </td>
                                      <td>
                                        <DatePicker
                                          selected={
                                            new Date(
                                              today.concat(
                                                " ",
                                                dayRow.eveningStart
                                              )
                                            )
                                          }
                                          onChange={(e) =>
                                            handleChange(
                                              e,
                                              "eveningStart",
                                              dayRow.day
                                            )
                                          }
                                          className={
                                            dayRow.status == "Closed"
                                              ? "closed_time"
                                              : "open_time"
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeCaption="Time"
                                          timeIntervals={15}
                                          dateFormat="h:mm aa"
                                          minTime={
                                            new Date(
                                              today.concat(" ", "12:00 pm")
                                            )
                                          }
                                          maxTime={
                                            new Date(
                                              today.concat(" ", "8:00 pm")
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />

                                        {/* <input
                                          type="time"
                                          className={
                                            dayRow.eveningStart
                                              ? "form_control_new closed_time"
                                              : "form_control_new open_time"
                                          }
                                          name="eveningStart"
                                          value={dayRow.eveningStart}
                                          onChange={(e) =>
                                            handleChange(e, dayRow.day)
                                          }
                                        /> */}
                                      </td>
                                      <td>
                                        <DatePicker
                                          selected={
                                            new Date(
                                              today.concat(
                                                " ",
                                                dayRow.eveningEnd
                                              )
                                            )
                                          }
                                          onChange={(e) =>
                                            handleChange(
                                              e,
                                              "eveningEnd",
                                              dayRow.day
                                            )
                                          }
                                          className={
                                            dayRow.status == "Closed"
                                              ? "closed_time"
                                              : "open_time"
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeCaption="Time"
                                          timeIntervals={15}
                                          dateFormat="h:mm aa"
                                          minTime={
                                            new Date(
                                              today.concat(" ", "12:00 pm")
                                            )
                                          }
                                          maxTime={
                                            new Date(
                                              today.concat(" ", "11:45 pm")
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                        {/* <input
                                          type="time"
                                          className={
                                            dayRow.eveningEnd
                                              ? "form_control_new closed_time"
                                              : "form_control_new open_time"
                                          }
                                          name="eveningEnd"
                                          value={dayRow.eveningEnd}
                                          onChange={(e) =>
                                            handleChange(e, dayRow.day)
                                          }
                                        /> */}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Table row End */}

              {/* Table row starts */}
              <div className="row special_days mt-5">
                <div className="col-xl-12">
                  <div className="members_tbl">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative">
                          <h4 className="title_text">Special Days</h4>
                          <button onClick={handleAddDays} className="plus_btn">
                            <img
                              src={require("../../../assets/images/plus_sign.png")}
                            />
                          </button>
                        </div>
                        <form>
                          <div className="table-responsive table-defaut-design special_days_table">
                            <table
                              id="datatable"
                              className="table dt-responsive dealers_table nowrap w-100 table_width"
                            >
                              <thead>
                                <tr>
                                  <th>Date</th>
                                  <th>Open/Closed</th>
                                  <th>Morning Start</th>
                                  <th>Morning End</th>
                                  <th>Evening Start</th>
                                  <th>Evening End</th>
                                </tr>
                              </thead>
                              <tbody className="td_color td_blue trans_table_fix_height">
                                {specialDaysArray?.map((spDay, i) => {
                                  return (
                                    <tr key={i}>
                                      <td className="calendar_date">
                                        <input
                                          min={today}
                                          type="date"
                                          name="date"
                                          value={spDay.date}
                                          onChange={(e) =>
                                            handleSpecialChange(e, spDay.date)
                                          }
                                        />
                                      </td>
                                      <td>
                                        <select
                                          onChange={(e) =>
                                            handleDateSelectChange(
                                              e,
                                              spDay.date
                                            )
                                          }
                                          className="form_control_new form_control_new"
                                        >
                                          <option
                                            defaultValue={spDay.status}
                                            selected
                                            hidden
                                          >
                                            {spDay.status}
                                          </option>
                                          <option value="Closed">Closed</option>
                                          <option value="Open">Open</option>
                                        </select>
                                      </td>
                                      <td>
                                        <DatePicker
                                          selected={
                                            new Date(
                                              today.concat(
                                                " ",
                                                spDay.morningStart
                                              )
                                            )
                                          }
                                          onChange={(e) =>
                                            handleSpecialTimeChange(
                                              e,
                                              "morningStart",
                                              spDay.date
                                            )
                                          }
                                          className={
                                            spDay.status == "Closed"
                                              ? "closed_time"
                                              : "open_time"
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeCaption="Time"
                                          timeIntervals={15}
                                          dateFormat="h:mm aa"
                                          minTime={
                                            new Date(
                                              today.concat(" ", "12:00 am")
                                            )
                                          }
                                          maxTime={
                                            new Date(
                                              today.concat(" ", "11:45 am")
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                        {/* <input
                                          type="time"
                                          className={
                                            spDay.morningStart
                                              ? "form_control_new closed_time"
                                              : "form_control_new open_time"
                                          }
                                          name="morningStart"
                                          value={spDay.morningStart}
                                          onChange={(e) =>
                                            handleSpecialChange(e, spDay.date)
                                          }
                                        /> */}
                                      </td>
                                      <td>
                                        <DatePicker
                                          selected={
                                            new Date(
                                              today.concat(
                                                " ",
                                                spDay.morningEnd
                                              )
                                            )
                                          }
                                          onChange={(e) =>
                                            handleSpecialTimeChange(
                                              e,
                                              "morningEnd",
                                              spDay.date
                                            )
                                          }
                                          className={
                                            spDay.status == "Closed"
                                              ? "closed_time"
                                              : "open_time"
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeCaption="Time"
                                          timeIntervals={15}
                                          dateFormat="h:mm aa"
                                          minTime={
                                            new Date(
                                              today.concat(" ", "12:00 am")
                                            )
                                          }
                                          maxTime={
                                            new Date(
                                              today.concat(" ", "11:45 am")
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                        {/* <input
                                          type="time"
                                          className={
                                            spDay.morningEnd
                                              ? "form_control_new closed_time"
                                              : "form_control_new open_time"
                                          }
                                          name="morningEnd"
                                          value={spDay.morningEnd}
                                          onChange={(e) =>
                                            handleSpecialChange(e, spDay.date)
                                          }
                                        /> */}
                                      </td>
                                      <td>
                                        <DatePicker
                                          selected={
                                            new Date(
                                              today.concat(
                                                " ",
                                                spDay.eveningStart
                                              )
                                            )
                                          }
                                          onChange={(e) =>
                                            handleSpecialTimeChange(
                                              e,
                                              "eveningStart",
                                              spDay.date
                                            )
                                          }
                                          className={
                                            spDay.status == "Closed"
                                              ? "closed_time"
                                              : "open_time"
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeCaption="Time"
                                          timeIntervals={15}
                                          dateFormat="h:mm aa"
                                          minTime={
                                            new Date(
                                              today.concat(" ", "12:00 pm")
                                            )
                                          }
                                          maxTime={
                                            new Date(
                                              today.concat(" ", "8:00 pm")
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                        {/* <input
                                          type="time"
                                          className={
                                            spDay.eveningStart
                                              ? "form_control_new closed_time"
                                              : "form_control_new open_time"
                                          }
                                          name="eveningStart"
                                          value={spDay.eveningStart}
                                          onChange={(e) =>
                                            handleSpecialChange(e, spDay.date)
                                          }
                                        /> */}
                                      </td>
                                      <td>
                                        <DatePicker
                                          selected={
                                            new Date(
                                              today.concat(
                                                " ",
                                                spDay.eveningEnd
                                              )
                                            )
                                          }
                                          onChange={(e) =>
                                            handleSpecialTimeChange(
                                              e,
                                              "eveningEnd",
                                              spDay.date
                                            )
                                          }
                                          className={
                                            spDay.status == "Closed"
                                              ? "closed_time"
                                              : "open_time"
                                          }
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeCaption="Time"
                                          timeIntervals={15}
                                          dateFormat="h:mm aa"
                                          minTime={
                                            new Date(
                                              today.concat(" ", "12:00 pm")
                                            )
                                          }
                                          maxTime={
                                            new Date(
                                              today.concat(" ", "11:45 pm")
                                            )
                                          }
                                          onKeyDown={(e) => {
                                            e.preventDefault();
                                          }}
                                        />
                                        {/* <input
                                          type="time"
                                          className={
                                            spDay.eveningEnd
                                              ? "form_control_new closed_time"
                                              : "form_control_new open_time"
                                          }
                                          name="eveningEnd"
                                          value={spDay.eveningEnd}
                                          onChange={(e) =>
                                            handleSpecialChange(e, spDay.date)
                                          }
                                        /> */}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Table row End */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SetAvailability;
