import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { assignNewDoctor } from '../../../Redux/Actions/admin/adminPanel';

function MyVerticallyCenteredModal(props) {
    const dispatch = useDispatch();

    const handleAssignDoctor = () => {
        dispatch(assignNewDoctor({ consId: props.consId, dentistId: sessionStorage.getItem("id") }))
        props.onHide();
    }

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                {/* <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                {/* <h4>Centered Modal</h4> */}
                <p className='text-center mt-5 mb-5 confirm-text'> 
                    Are you sure you want to accept this booking?
                </p>
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <Button onClick={() => handleAssignDoctor()} className='btn pending_genr'>Yes</Button>
                <Button onClick={props.onHide}  className='btn pending_genr'>No</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default function ConfirmPopup({ modalShow, setModalShow, consId }) {

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Launch vertically centered modal
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => { setModalShow(false) }}
                consId={consId}
            />
        </>
    );
}

