import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { assignDoctor } from "../../../Redux/Actions/admin/adminPanel";

const DoctorSelect = ({ consInfo, admins }) => {
  const dispatch = useDispatch();
  const handleClick = (admin) => {
    dispatch(assignDoctor({ adminId: admin._id, consId: consInfo?.cons?._id }));
  };

  return (
    <Dropdown className="dropdown_white">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {consInfo?.cons?.assignedDoctor
          ? consInfo?.cons?.assignedDoctor?.firstName.concat(
              " ",
              consInfo?.cons?.assignedDoctor?.lastName
            )
          : "Not Assigned"}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {/* <Dropdown.Item href="#/action-1">Action</Dropdown.Item> */}
        {admins &&
          admins?.map((admin) => {
            return (
              <Dropdown.Item onClick={() => handleClick(admin)} key={admin._id}>
                {admin.firstName.concat(" ", admin.lastName)}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DoctorSelect;
