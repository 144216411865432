import React, { useState } from "react";
import LayoutSymtom from "../../Layout/LayoutSymtom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { contactUs } from "../../../Redux/Actions/user/auth";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";
import { Link, useNavigate } from "react-router-dom";
import { toastSuccess } from "../../../Redux/Reducers/admin/adminPanelSlice";

const ContactUs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const validate = (values) => {
    const errors = {};
    if (!values.fullName) {
      errors.fullName = "Enter full name";
    } else if (!/^(?=.*[a-zA-Z])[A-Za-z0-9]+$/.test(values.firstName)) {
      errors.firstName = "Number not allowed";
    }

    if (!values.email) {
      errors.email = "Enter email";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Enter correct email";
    }

    if (!values.subject) {
      errors.subject = "Enter subject";
    }
    if (!values.message) {
      errors.message = "Enter message";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      subject: "",
      message: "",
    },
    validate,
    onSubmit: async (values) => {
      try {
        dispatch(startstopLoading(true));
        dispatch(contactUs(values)).then((res) => {
          console.log(res, "sadfw");
          if (res?.payload) {
            dispatch(startstopLoading(false));
            toastSuccess(res.payload.message);
            navigate("/");
          }
        });
      } catch (error) {
        console.log("error");
      }
    },
  });
  return (
    <>
      <LayoutSymtom>
        <section class="contact_us_form symptom-checker m-120">
          <div class="container">
            <div className="row d-flex justify-content-center">
              <h2 className="text-center mb-5">Contact Us</h2>
              <div className="col-md-4 display-table">
                <div className="table-cell card_shadow card h-auto p-4">
                  <div className="contact-new-box ">
                    <div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={require("../../../assets/images/contactus/contact-1.png")}
                        />
                      </div>
                      <h3>Need immediate assistance?</h3>

                      <p>
                        Our team at Toothaid is ready to assist you and will
                        respond as swiftly as possible during our operating
                        hours (Monday to Friday: 8am to 7pm and Saturday: 8am to
                        12pm).
                      </p>
                      <p>
                        If you’re already a Toothaid patient, the fastest way to
                        get in touch is through the Toothaid portal. Just tap
                        the 'Help and Support" tab and submit a request.
                      </p>
                      <p>
                        If you’re new to Toothaid, you can connect with us using
                        Live Chat.
                      </p>
                    </div>
                    <button onClick={() => navigate("/register")}>Start Live Chat </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 display-table ">
                <div className="table-cell card_shadow card h-auto p-4">
                  <div className="contact-new-box ">
                    <div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={require("../../../assets/images/contactus/contact-2.png")}
                        />
                      </div>
                      <h3>Live chat</h3>
                      <p>
                        Reach out to our dedicated team for any general
                        inquiries and experience the convenience of real-time
                        support through our LiveChat service.
                      </p>
                      <p>
                        {" "}
                        <b> Hours of operation</b> <br />
                        Monday to Friday: 8am to 7pm <br />
                        Saturday: 8am to 12pm
                      </p>
                    </div>
                    <button onClick={() => navigate("/register")}>Start Live Chat </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4 display-table">
                <div className=" table-cell card_shadow card h-auto p-4">
                  <div className="contact-new-box ">
                    <div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={require("../../../assets/images/contactus/contact-3.png")}
                        />
                      </div>
                      <h3>Email us</h3>
                      <p>
                        Reach out to us anytime, day or night. We’re here to
                        help! Drop us an email, and our team will respond within
                        a couple of business hours. Get in touch for quick and
                        responsive assistance.
                      </p>
                      <p>
                        <b> Email</b>
                        <br />
                        <Link to="mailto:info@toothaid.co">info@toothaid.co</Link>
                      </p>
                    </div>
                    <button onClick={() => {
                      window.location.href = "mailto:info@toothaid.co";
                    }}>Email us now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutSymtom>
    </>
  );
};

export default ContactUs;
