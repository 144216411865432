import Layout from "../layout/Layout";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editBlog } from "../../../Redux/Actions/admin/adminPanel";
import { useNavigate, useParams } from "react-router-dom";
import { viewBlog } from "../../../Redux/Actions/admin/adminPanel";
import RTE from "./Rte";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";

const EditBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [img, setImg] = useState();
  const [htmlBlogContent, setHtmlBlog] = useState();
  const [givenContent, setGivenContent] = useState();

  const blog = useSelector((state) => state.adminPanel.viewBlog);

  // const HOST_NAME = process.env.REACT_APP_HOST_NAME;

  const handleHtml = (html) => {
    setHtmlBlog(html);
  };

  useEffect(() => {
    dispatch(viewBlog(id));
  }, []);

  useEffect(() => {
    if (blog?._id == id) {
      setHtmlBlog(blog?.blogContent);
      setGivenContent(blog?.blogContent);
      setImg(blog?.img);
      formik.values.title = blog?.title;
      formik.values.author = blog?.author;
    }
  }, [blog]);

  const validate = (values) => {
    const errors = {};
    if (!values.title) {
      errors.title = "Required";
    }
    if (!values.author) {
      errors.author = "Required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      author: "",
      blogContent: "",
    },

    validate,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("file", img);
      formData.append("title", values.title);
      formData.append("author", values.author);
      formData.append("blogContent", htmlBlogContent);
      formData.append("id", id);

      try {
        dispatch(startstopLoading(true));
        await dispatch(editBlog(formData))
          .then(navigate("/admin/blog"))
          .then((res) => {
            if (
              res?.payload?.success == true ||
              res?.payload?.success == false
            ) {
              dispatch(startstopLoading(false));
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <Layout>
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <div>
                      <span className="small_text">Blogs</span>
                      <h4 className="mb-sm-0 font-size-28">Edit Blog</h4>
                    </div>
                    <div className="page-title-right"></div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="tbl_user_info">
                    <div className="row">
                      <form onSubmit={formik.handleSubmit}>
                        <div className="col-md-8">
                          <div className="form-group mb-3">
                            <input
                              onChange={formik.handleChange}
                              name="title"
                              type="text"
                              className="form-control-new"
                              placeholder="Blog Title"
                              value={formik.values.title}
                            />
                            {formik.errors.title ? (
                              <p style={{ color: "red" }}>
                                {formik.errors.title}*
                              </p>
                            ) : null}
                          </div>
                          <div className="form-group mb-3">
                            <input
                              onChange={formik.handleChange}
                              name="author"
                              type="text"
                              className="form-control-new"
                              placeholder="Author"
                              value={formik.values.author}
                            />
                            {formik.errors.author ? (
                              <p style={{ color: "red" }}>
                                {formik.errors.author}*
                              </p>
                            ) : null}
                          </div>
                          <div className="form-group mb-5">
                            <input
                              onChange={(e) => setImg(e.target.files[0])}
                              type="file"
                              accept="image/*"
                              className="blog-upload-input"
                              placeholder="Upload Image"
                            />
                            <div className="blog-upload-img form-control-new">
                              {!img?.name ? img : img?.name}
                            </div>
                          </div>
                          <div>
                            {givenContent && (
                              <RTE
                                givenContent={givenContent}
                                htmlText={handleHtml}
                              />
                            )}
                          </div>
                          <div
                            className="editor-controls"
                            id="editor-controls"
                          ></div>

                          <div className="d-flex justify-content-end">
                            <button
                              type="submit"
                              style={{ border: "none" }}
                              className="blog-post-submt-btn"
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EditBlog;
