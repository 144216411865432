import React, { useEffect } from "react";
import Layout from "../layout/Layout";
import admin_avatar_header from "../../../assets/images/avatar.svg";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { getCalendarEvents, getDentist } from "../../../Redux/Actions/admin/adminPanel";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { toastInfo } from "../../../Redux/Reducers/admin/adminPanelSlice";
import SetAvailability from "./SetAvailability";
import { useState } from "react";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";
const Calendar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const events = useSelector((state) => state.adminPanel.calendarEvents);
  const dentists = useSelector((state) => state.adminPanel.dentists);
  const [filter, setFilter] = useState({
    assigned_to: undefined
  })

  useEffect(() => {
    dispatch(startstopLoading(true))
    dispatch(getDentist({ filter: filter }))
    dispatch(getCalendarEvents({
      filter: sessionStorage.getItem("role") == 'dentist' ?
        { assigned_to: sessionStorage.getItem("id") } : filter
    })).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false))
      }
    });
  }, []);

  const handleFilter = (e) => {
    let { value, name } = e.target;
    setFilter({ ...filter, [name]: value })
    dispatch(getCalendarEvents({ filter: { ...filter, [name]: value } }));
  }

  const handleEventClick = (arg) => {
    const consultation_id = arg.event._def.extendedProps._id;
    navigate(`/admin/adminConsultation/${consultation_id}`);
    // if (
    //   (new Date() < moment(arg.event.start) &&
    //     new Date() > moment(arg.event.start) - 10 * 60 * 1000) ||
    //   (new Date() <= moment(arg.event.start) + 5 * 60 * 1000 &&
    //     new Date() > moment(arg.event.start))
    // ) {

    // } else if (new Date() > moment(arg.event.start)) {
    //   console.log("open");
    // } else {
    //   toastInfo("Cannot start the following Consultation");
    // }
  };

  console.log(events, "events")

  return (
    <Layout>
      {/* {avail == false && ( */}
      <div id="layout-wrapper" className="bg-dash admin-table">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between position-relative">
                    <div>
                      <span className="small_text">Calendar</span>
                      <h4 className="mb-sm-0 font-size-28">Calendar</h4>
                    </div>
                    {/* <button
                      onClick={() =>
                        navigate("/admin/calendar/setAvailability")
                      }
                      className="calendar_btn"
                    >
                      Set-Availability
                      <img
                        src={require("../../../assets/images/calendar.png")}
                      />
                    </button> */}
                    {/* <div className="page-title-right">
                      <form className="app-search d-none d-lg-block ">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="position-relative w-100">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                            />
                            <span className="bx bx-search"></span>
                          </div>
                          <img
                            src={admin_avatar_header}
                            className="avata_serch"
                            alt=""
                          />
                        </div>
                      </form>
                    </div> */}
                  </div>
                </div>
              </div>
              {
                sessionStorage.getItem("role") != "dentist" &&

                <div className="col-xxl-2 col-xl-4 col-lg-4 col-md-4">
                  <div class="form-group mb-4">

                    <select
                      name="assigned_to"
                      // value={item?.assignedDoctor}
                      onChange={(e) => handleFilter(e)}
                      class="form-control filter-select-element"
                    //disabled={true}
                    >
                      <option value={""}>Assigned To</option>
                      {
                        dentists?.map((item) => {
                          return (
                            <>
                              <option value={item?._id}>{item?.firstName + " " + item?.lastName}</option>
                            </>
                          )
                        })
                      }
                    </select>
                  </div>
                </div>

              }

              <div className="row">
                <div className="col-xl-12">
                  <div className="members_tbl">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 position-relative"></div>
                        <div className="table-responsive table-defaut-design">
                          <div className="full-calendar">
                            <FullCalendar
                              plugins={[dayGridPlugin, timeGridPlugin]}
                              headerToolbar={{
                                left: "prev,next today",
                                center: "title",
                                // right: "dayGridMonth,timeGridWeek,timeGridDay",
                                right: "",
                              }}
                              initialView="dayGridMonth"
                              events={events}
                              eventClick={handleEventClick}
                              eventTimeFormat={{
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }}
                            />
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-12"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- table row end --> */}
            </div>{" "}
            {/* <!-- container fluid --> */}
          </div>{" "}
          {/* <!-- End Page-content --> */}
        </div>
        {/* <!-- end main content--> */}
      </div>
      {/* )} */}

      {/* {avail == true && <SetAvailability  />} */}
    </Layout>
  );
};

{
  /*} <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay, listWeek",
                    }}
                    initialView="dayGridMonth"
                  /> */
}

export default Calendar;
