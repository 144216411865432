import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toastfunc, toastSuccess } from "../../../Redux/Reducers/user/ConsultationSlice";
import { LpSymtomCheckerResultImg1 } from "../lp_symtom_checker/LpSymtomCheckerResultImg1";
import { ResultPage } from "./ResultPage";
import { HashLink } from "react-router-hash-link";
import TMD from "../common_issues/TMD";
import MouthUlcers from "../common_issues/MouthUlcers";
import { toastInfo } from "../../../Redux/Reducers/admin/adminPanelSlice";
import Modal from "react-bootstrap/Modal";
import ModalView from "./ModalView";
import ModalViewUlcers from "./ModalViewUlcers";
import { io } from "socket.io-client";
export default function SymptomCheckerOne({
  activeClickData,
  setImgResult,
  imgResult,
  tmdVal,
  setSp,
}) {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const navigate = useNavigate();
  const [socket, setSocket] = useState();
  const [isTMD, setShowTMD] = useState(false);
  const [isUlcers, setShowUlcers] = useState(false);
  const [dataArr, setDataArr] = useState([]);
  const [jawArr, setJawArr] = useState([]);
  const [viewVal, setShowVal] = useState(false);
  const [viewModal, setShowModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [colorData, setColorData] = useState({
    red: 0,
    black: 0,
    brown: 0,
    pink: 0,
    strictRed: 0,
    strictBlack: 0,
    special: 0,
  });
  const [colData, setColData] = useState({
    red: 0,
    black: 0,
    brown: 0,
    pink: 0,
    strictRed: 0,
    strictBlack: 0,
    special: 0,
  });
  const [reData, setRedata] = useState();
  useEffect(() => {
    setShowTMD(false);
    setShowUlcers(false);
  }, [tmdVal]);

  useEffect(() => {
    setDataArr([activeClickData?.name[0]]);
  }, [activeClickData]);
  const handleActiveClick = (
    itemParent,
    index,
    indexoption,
    colorCode,
    strictcode,
    item
  ) => {
    dataArr?.forEach((element, i) => {
      if (index == i) {
        element?.options?.forEach((ele, optIndex) => {
          if (indexoption == optIndex) {
            ele.active = true;
          } else {
            ele.active = false;
          }
        });
      }
    });

    for (const key in colorData) {
      if (key == colorCode && strictcode != "") {
        setColorData({
          ...colorData,
          [key]: colorData[key] + 1,
          [strictcode]: colorData[strictcode] + 1,
        });
        setColData({
          ...colorData,
          [key]: colorData[key] + 1,
          [strictcode]: colorData[strictcode] + 1,
        });
      } else if (key == colorCode && strictcode == "") {
        setColorData({ ...colorData, [key]: colorData[key] + 1 });
        setColData({ ...colorData, [key]: colorData[key] + 1 });
      }
    }
    setDataArr([...dataArr]);
    const mainInd = dataArr?.findIndex((element) => element.id == index + 1);
    if (mainInd == -1 && activeClickData?.name[itemParent + 1] != undefined) {
      setDataArr([...dataArr, activeClickData?.name[itemParent + 1]]);
    } else {
      setDataArr([dataArr[0]]);
      setColorData({
        red: 0,
        black: 0,
        brown: 0,
        pink: 0,
        strictRed: 0,
        strictBlack: 0,
        special: 0,
      });
    }
    if (activeClickData?.key == "Headaches/Migraines" && itemParent == 5) {
      if (colData.special == 1 && colData.red < colData.black) {
        setShowModal(true);
        setImgResult(false);

        if (socket) {
          socket.emit('symptom_result', { headacheMigraine: 1 });
        }

      } else {
        setImgResult(true);

        if (socket) {
          socket.emit('symptom_result', { headacheMigraine: 1 });
        }
      }
    } else {
      setSp(false);
    }

    if (activeClickData?.key == "Ulcers" && itemParent == 0) {
      // if (indexoption == 0) {
      //   navigate("/diagnosis#mouth_ulcers");
      // }
      if (indexoption == 1) {
        if (sessionStorage.getItem("token")) {
          navigate("/consultations");
        } else {
          navigate("/userlogin");
        }
      }
    }
    if (activeClickData?.key == "Jaw Ache") {
      if (!jawArr.includes(item)) {
        setJawArr([...jawArr, item]);
      } else {
        const index = jawArr?.indexOf(item);
        if (index > -1) {
          jawArr.splice(index, 1);
          setJawArr([]);
          setJawArr(jawArr);
        }
      }
    }
  };

  useEffect(() => {
    const newSocket = io.connect(BASE_URL);
    setSocket(newSocket);

    newSocket.on('symptom_result_sucess', (data) => {
      // toastSuccess(data?.message);  
    });

    return () => newSocket.close();
  }, []);


  const handleImgClick = (answer) => {
    setImgResult(true);
    setRedata(answer);

    if (socket) {
      socket.emit('symptom_result', { dentalPain: 1 });
    }
  };

  const handleImgResult = () => {
    setImgResult(true)
    if (socket) {
      socket.emit('symptom_result', { dentalPain: 1 });
    }
  }


  const handleClose = () => {
    setShowVal(false);
  };

  const handleCheckClick = () => {
    const jawData = jawArr.find(
      (item) => item == "Swelling in your gums around your back tooth"
    );
    if (jawData) {
      setImgResult(true);
      setCheck(true);

      if (socket) {
        socket.emit('symptom_result', { jawAche: 1 });
      }
    } else if (jawArr.length > 0) {
      // navigate("/diagnosis");
      setShowVal(true);
      setShowTMD(true);
      // toastInfo("Provisional Diagnosis");

      if (socket) {
        socket.emit('symptom_result', { jawAche: 1 });
      }
    } else {
      toastfunc("Please select atleast one symptom.");
    }
  };

  const handleUlcerClick = () => {
    setShowUlcers(true);
    setShowVal(true);

    if (socket) {
      socket.emit('symptom_result', { ulcer: 1 });
    }
  };
  return (
    <>
      <Modal
        show={viewVal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="admin-popup provisional-popup"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> Provisional Diagnosis: TMD</h4>
          <div className=" table-defaut-design text_inner">
            From the information you've provided, we have concluded a
            provisional diagnosis. For a more accurate diagnosis, please book a
            consultation with one of our team.
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="btn_submit ">
            <button
              onClick={handleClose}
              className="btn btn-primary btn-custom btn-lg w-100 submit_btn confirmation_btn"
            >
              Okay
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <ModalView
        viewModal={viewModal}
        setShowModal={setShowModal}
        setSp={setSp}
      />
      <ModalViewUlcers
        modalShow={modalShow}
        setModalShow={setModalShow}
        handleUlcerClick={handleUlcerClick}
      />
      {isTMD ? (
        <TMD />
      ) : isUlcers ? (
        <MouthUlcers />
      ) : (
        <div className="col-xl-10 col-lg-9">
          <div className="problem_desc border-radius-prop  margin_top_20 issues_sub_page">
            <h2 className="main-title mb-0">{activeClickData?.key}</h2>

            {imgResult == false && (
              <div className="questions_part  d-flex flex-column justify-content-between">
                {dataArr?.map((itemParent, index) => {
                  return (
                    <div className="ques_detail  option_margin ">
                      <div className="ques_detail_img ">
                        {activeClickData?.key == "Ulcers" && (
                          <HashLink>
                            <img
                              onClick={() => {
                                // handleUlcerClick();
                                setModalShow(true);
                              }}
                              className="ulcer_img_logged_in"
                              src={require("../../../assets/images/problems/ulcers.png")}
                            />
                          </HashLink>
                        )}

                        {activeClickData?.key == "Ulcers" && (
                          <a
                          // to={"/diagnosis/#Acute-apical-abscess"}
                          >
                            <img
                              onClick={() => {
                                // handleUlcerClick()
                                setModalShow(true);
                              }}
                              className="ulcer_img_logged_in"
                              src={require("../../../assets/images/img-4.jpeg")}
                            />
                          </a>
                        )}
                      </div>

                      <div
                        className={`d-flex justify-content-between align-items-center ques_detail-inner ${activeClickData?.key == "Jaw Ache" &&
                          "jaw_ques_detail-inner"
                          } 
                        ${activeClickData?.key == "Ulcers"
                            ? " ulcer-options-width"
                            : ""
                          }
                        `}
                      >
                        <p className="mb-0">{itemParent?.ques}</p>
                        <div
                          className={`options_part questions_options ${activeClickData?.key == "Jaw Ache" &&
                            "jaw_questions_options"
                            }`}
                        >
                          <ul
                            className={`mb-0 d-flex p-0 ${activeClickData?.key == "Ulcers" &&
                              "content-center"
                              } ${activeClickData?.key == "Jaw Ache" &&
                              "jaw_symptom_ul"
                              }`}
                          >
                            {itemParent?.id == 9 ? (
                              <li>
                                <div className="d-flex justify-content-between">
                                  {itemParent?.options?.map((item, i) => {
                                    return (
                                      item?.img != undefined && (
                                        <div
                                          key={i}
                                          className="symptom-img symptom_img_depiction"
                                        >
                                          <img
                                            onClick={() =>
                                              handleImgClick(item.answer)
                                            }
                                            src={require("../../../assets/images/" +
                                              item?.img)}
                                          />
                                        </div>
                                      )
                                    );
                                  })}
                                </div>
                                <div
                                  onClick={() => handleImgResult()}
                                  className="option-btn mt-2 btn_end"
                                >
                                  {itemParent?.options[2]?.type}
                                </div>
                              </li>
                            ) : (
                              itemParent?.options?.map((item, indexoption) => {
                                return activeClickData?.key == "Ulcers" ? (
                                  <HashLink
                                    className="ulcer-option-inner"
                                    onClick={() => {
                                      indexoption == 0
                                        ? handleUlcerClick()
                                        : indexoption == 1
                                          ? navigate("/diagnosis")
                                          : toastInfo(
                                            "We recommend booking a consultation for video assessment"
                                          );
                                    }}
                                    to={
                                      (indexoption == 2 && "/consultations") ||
                                      (indexoption == 1 &&
                                        "/diagnosis/#Acute-apical-abscess")
                                    }
                                  >
                                    <li
                                      onClick={() =>
                                        handleActiveClick(
                                          itemParent?.id,
                                          index,
                                          indexoption,
                                          item?.colorCode,
                                          item?.strictCode,
                                          item?.type
                                        )
                                      }
                                      className={
                                        activeClickData?.key == "Jaw Ache" &&
                                        "jaw_symptom_li"
                                      }
                                    >
                                      <a
                                        className={`select_option_btn cursor-pointer w-75  ${activeClickData?.key == "Jaw Ache"
                                            ? jawArr?.includes(item?.type)
                                              ? "active-btn jaw_select_option_btn"
                                              : "jaw_select_option_btn"
                                            : item?.active == true
                                              ? "active-btn"
                                              : ""
                                          } ${activeClickData?.key == "Dental Pain"
                                            ? "h-38 fixHeight "
                                            : ""
                                          } 
                                        
                                        `}
                                      >
                                        {item?.type}
                                      </a>
                                    </li>
                                  </HashLink>
                                ) : (
                                  <li
                                    onClick={() =>
                                      handleActiveClick(
                                        itemParent?.id,
                                        index,
                                        indexoption,
                                        item?.colorCode,
                                        item?.strictCode,
                                        item?.type
                                      )
                                    }
                                    className={
                                      activeClickData?.key == "Jaw Ache" &&
                                      "jaw_symptom_li"
                                    }
                                  >
                                    <a
                                      className={`select_option_btn cursor-pointer w-75 ${activeClickData?.key == "Jaw Ache"
                                          ? jawArr?.includes(item?.type)
                                            ? "active-btn jaw_select_option_btn"
                                            : "jaw_select_option_btn"
                                          : item?.active == true
                                            ? "active-btn"
                                            : ""
                                        }  ${activeClickData?.key == "Dental Pain"
                                          ? "h-38 fixHeight"
                                          : ""
                                        }  `}
                                    >
                                      {item?.type}
                                    </a>
                                  </li>
                                );
                              })
                            )}
                          </ul>
                          {activeClickData?.key == "Jaw Ache" && (
                            <div className="d-flex justify-content-center">
                              <button
                                onClick={() => handleCheckClick()}
                                className={`btn btn-primary white-btn ${activeClickData?.key == "Jaw Ache" &&
                                  "jaw_white-btn"
                                  }`}
                              >
                                Check
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}

                {/* <div className="next_page_btn">
              <a href="#" className="btn dark_btn">
                Next
              </a>
            </div> */}
              </div>
            )}
            {imgResult == true && (
              <ResultPage
                check={check}
                setAnswer={reData}
                colorData={colorData}
                type={
                  activeClickData?.key == "Headaches/Migraines"
                    ? "Headache"
                    : "Migraine"
                }
                colData={colData}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
