import React from "react";
import LayoutSymtom from "./UserPanel/Layout/LayoutSymtom";
export default function PersonalDataRetentionPolicy() {
  return (
    <LayoutSymtom>
      {" "}
      <div class="container">
        <div class="privacy_text">
          {" "}
          <table>
            <tr>
              <th>
                Business Purpose/Activity and types of data (expand as required)
              </th>
              <th>Category of Data</th>
              <th>Trigger Event</th>
              <th>Retain for this period after trigger event</th>
              <th>Must keep for minimum period?</th>
              <th>Action</th>
            </tr>
            <tr>
              <td>
                <b>Recruitment</b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Job application</td>
              <td></td>
              <td>Rejection</td>
              <td>[3 months]</td>
              <td></td>
              <td>Delete</td>
            </tr>
            <tr>
              <td>Qualifications and CV</td>
              <td></td>
              <td>Rejection</td>
              <td>[3 months]</td>
              <td></td>
              <td>Delete</td>
            </tr>
            <tr>
              <td>References</td>
              <td></td>
              <td>Rejection</td>
              <td>[3 months]</td>
              <td></td>
              <td>Delete</td>
            </tr>
            <tr>
              <td>
                <b>Personnel Management</b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Sickness records</td>
              <td>Special</td>
              <td>Departure</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Occupational Health</td>
              <td>Special</td>
              <td>Departure</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Performance management</td>
              <td></td>
              <td>Departure</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Disciplinary Proceedings</td>
              <td></td>
              <td>End of proceedings/investigation</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>General HR</td>
              <td></td>
              <td>Departure</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Pensions</b>
              </td>
              <td></td>
              <td>Departure</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Tax</b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>Yes, HMRC</td>
              <td></td>
            </tr>
            <tr>
              <td>NI and income tax</td>
              <td></td>
              <td></td>
              <td></td>
              <td>Yes, HMRC</td>
              <td></td>
            </tr>
            <tr>
              <td>VAT</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Services to Patients</b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Dental /Treatment Records</td>
              <td>Special</td>
              <td>End of contract</td>
              <td></td>
              <td>Yes, GDC/Insurers</td>
              <td>
                Minimise (to allow for tracing) then delete remainder. Keep
                retained data segregated , secure and pseudonymised.
              </td>
            </tr>
            <tr>
              <td>
                <b>Billing</b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Patients</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Suppliers</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Consultants’ fees</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Suppliers</b>
              </td>
              <td></td>
              <td>End of contract</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>External contractors/consultants</b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Dental Regulatory & Professional</b>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>Yes, GDC</td>
              <td></td>
            </tr>
            <tr>
              <td>CPD records</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Practising certificates</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>PII</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Postal Marketing</b>
              </td>
              <td></td>
              <td>End of contract</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>e-Marketing</b>
              </td>
              <td></td>
              <td>Unsubscribe received</td>
              <td>
                Delete unnecessary data within [1 month]. Ensure no more
                e-marketing sent.
              </td>
              <td></td>
              <td>
                Minimise data to retain unsubscribe choice and delete rest.
              </td>
            </tr>
            <tr>
              <td>
                <b>Legal Proceedings</b>
              </td>
              <td>General and Specialx</td>
              <td>End of proceedings</td>
              <td></td>
              <td>
                Yes, 6 years. Limitation period and insurers ‘requirements.
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                <b>Surveys</b>
              </td>
              <td>End of activity informed by survey results</td>
              <td>[6 months]</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </table>
          <p>
            The template has been produced by Gunnercooke llp for Toothaid
            Limited only.{" "}
          </p>
          <p>
            This Policy is intended to help the business ensure it only retains
            personal data for the minimum period necessary in order to achieve
            the purpose for which each data-set was collected.{" "}
          </p>
          <p>The company must adapt, populate and maintain the document. </p>
          <p>
            Where details have been inserted by us this is just for illustrative
            purposes. The document must be retained so that it is available for
            production to the ICO if required.
          </p>
          <p>
            In some cases, data must be retained for a specific minimum period
            by law eg tax records. The company will need to ensure it has taken
            account here of all relevant professional obligations (eg GDC) in
            relation to data protection and retention as well. In relation to
            each Business Purpose, the company may need to obtain advice on
            retention periods from their relevant advisers eg HR, tax etc.
            Similarly it should check with its insurers as to their
            requirements.
          </p>
        </div>
      </div>
    </LayoutSymtom>
  );
}
