import React from "react";
import Chat from "./Chat";
import io from "socket.io-client";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVideoReview, getUserConsInfo } from "../../../Redux/Actions/user/userAll";
import { useNavigate, useParams } from "react-router-dom";
import ConsNotes from "./ConsNotes";
import VideoCall from "./VideoCall";
import jwtDecode from "jwt-decode";
import JoinCallLayout from "../../Layout/JoinCallLayout";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
export default function JoinCall() {
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const [socket, setSocket] = useState();
  const dispatch = useDispatch();
  const socketRef = useRef();
  const { id } = useParams();
  const userConsInfo = useSelector((state) => state.consultaions.consInfo);
  const [me, setMe] = useState("");
  const [onlineUsers, setOnlineUsers] = useState();

  const navigate = useNavigate()

  const [rating, setRating] = useState(0);
  const [state, setState] = useState({
    video_call_rating: rating,
    video_call_comment: "",
  });

  useEffect(() => {
    socketRef.current = io.connect(BASE_URL);
    socketRef.current.emit(
      "video-user-add",
      jwtDecode(sessionStorage.getItem("token")).id
    );
    socketRef.current.on("me", (myId) => {
      setMe(myId);
    });
    setSocket(socketRef.current);
    dispatch(getUserConsInfo(id));
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    socketRef.current.on("video-users", (users) => {
      setOnlineUsers(users);
    });
  }, [socketRef.current]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async () => {
    try {
      let obj = { ...state, consultation_id: id }
      await dispatch(addVideoReview(obj)).then(() => {
        handleClose();
        navigate("/accountHome")
        toast.success("Thank you for your review.");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRating = (rate) => {
    setRating(rate);
    setState({
      ...state,
      video_call_rating: rate,
    });
  };

  const modalFunc = () => {
    setShow(true);
  }


  return (
    <JoinCallLayout>
      <div className="col-lg-10">
        <div className="desc_area pb-0">
          <div className="row">
            <div className="col-lg-7">
              {onlineUsers && socket && userConsInfo && me ? (
                <VideoCall
                  me={me}
                  consInfo={userConsInfo?.cons}
                  socket={socketRef.current}
                  onlineUsers={onlineUsers}
                  modalFunc={modalFunc}
                />
              ) : (
                console.log(onlineUsers, socket, userConsInfo, me)
              )}
              {userConsInfo && socket ? (
                <Chat socket={socket} userConsInfo={userConsInfo} />
              ) : null}
            </div>
            {userConsInfo && socket ? (
              <ConsNotes socket={socket} userConsInfo={userConsInfo} />
            ) : null}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} className="rating-main-popup">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="rating-sec">
            <div className="row">
              <div className="col-lg-12">
                <h3>Rate the quality of the video call</h3>
              </div>
              <div className="col-lg-12 d-flex justify-content-center star-pattern">
                <Rating onClick={handleRating} initialValue={rating} />
              </div>
              <div className="col-lg-12 mt-4">
                <h5>Review(Optional)</h5>
                <textarea
                  rows={3}
                  placeholder=""
                  className="w-100"
                  onChange={(e) =>
                    setState({
                      ...state,
                      video_call_comment: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button variant="primary" className=" new-pres" onClick={handleSubmit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </JoinCallLayout>
  );
}
