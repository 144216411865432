import React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Layout from "../../Layout/Layout";
import ConsultationNotes from "./ConsultationNotes";
import UpCommingNotes from "./upCommingNotes";
import {
  addVideoReview,
  cancelConsultation,
  getConsultationDate,
} from "../../../Redux/Actions/user/userAll";
import MomentFunc from "../../../utils/MomentDateTime";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { startstopLoading } from "../../../Redux/Reducers/globalSlice";
import Modal from "react-bootstrap/Modal";
import ModalView from "./ModalView";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { Rating } from "react-simple-star-rating";

export default function Consultations() {
  const [consId, setConsId] = useState();
  const [notes, setNotes] = useState(false);
  const [notesData, setNotesData] = useState();
  const [joinCallDetail, setJoinCallDetail] = useState();
  const [agreeTerms, setAgreeTerms] = useState();

  const [consultationId, setConsultationId] = useState(null);

  const [rating, setRating] = useState(0);
  const [state, setState] = useState({
    video_call_rating: rating,
    video_call_comment: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pastConsultaions = useSelector(
    (state) => state?.consultaions?.pastConsultations
  );
  const upcomingConsultaion = useSelector(
    (state) => state?.consultaions?.upcomingConsultations
  );
  sessionStorage.setItem("consId", "undefined");
  const NotesRef = useRef();
  const handleNotesPrint = useReactToPrint({
    content: () => NotesRef.current,
  });

  const handleClose = () => {
    setNotes(false);
  };

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(getConsultationDate()).then((res) => {
      if (res?.payload) {
        dispatch(startstopLoading(false));
      }
    });
  }, []);

  const Notes = (item) => {
    setNotesData(item);
    setNotes(true);
  };
  const handleUpdate = () => {
    sessionStorage.setItem("consId", consId);
    navigate("/new-consultation");
  };

  const handleCancel = (id) => {
    // setCancel(cancel)
    dispatch(cancelConsultation(id));
    dispatch(getConsultationDate());
  };

  const handleClickJoinCall = (callId) => {
    navigate(`/join-call/${callId}`);
  };
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);


  const handleClose3 = () => setShow3(false);
  // const handleShow3 = (id) => {
  //   setConsultationId(id);
  //   setShow3(true);
  // }

  const handleShow3 = (id) => {
    const selectedConsultation = pastConsultaions?.find((item) => item?._id === id);
    if (selectedConsultation?.review) {
      setState({
        video_call_rating: selectedConsultation.review.user.video_call_rating,
        video_call_comment: selectedConsultation.review.user.video_call_comment,
      });
      setRating(selectedConsultation.review.user.video_call_rating);
    } else {
      setState({
        video_call_rating: 0,
        video_call_comment: "",
      });
      setRating(0);
    }
    setConsultationId(id);
    setShow3(true);
  };


  const handleSubmit = async () => {
    try {
      let obj = { ...state, consultation_id: consultationId }
      await dispatch(addVideoReview(obj)).then(() => {
        setRating(0)
        handleClose3();
        toast.success("Thank you for your review.");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRating = (rate) => {
    setRating(rate);
    setState({
      ...state,
      video_call_rating: rate,
    });
  };


  return (
    <Layout>
      <div className="col-lg-10 ">
        <div className="desc_area pb-0 ">
          <div className="row">
            <div className="col-lg-6">
              <div className="card mb-4">
                <div className="card-body height-330">
                  <div className="consulatation_card">
                    <div className="d-flex justify-content-between align-items-center new_consultation">
                      <h3 className="common_title">Upcoming Consultations</h3>
                      <Link
                        onClick={() =>
                          sessionStorage.setItem(
                            "consIdReschedule",
                            "undefined"
                          )
                        }
                        to="/new-consultation"
                        className="small_dark_btn small_drk_btn_margin"
                      >
                        New Consultation
                      </Link>
                    </div>
                    <div
                      className={
                        upcomingConsultaion?.length > 0
                          ? "overflow_table consultation_table upcoming_consultation_table"
                          : "overflow_table consultation_table scroll-hide"
                      }
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Doctor</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="overflow-y-scroll upcomingConsultation">
                          {upcomingConsultaion?.length ? (
                            upcomingConsultaion?.map((item, i) => {
                              return (
                                <tr className="box_prop" key={i}>
                                  <td>
                                    <p>{MomentFunc.Date(item?.date)}</p>
                                  </td>
                                  <td>
                                    <p>{MomentFunc.Time(item?.date)}</p>
                                    {/* <p>
                                    {}
                                  </p> */}
                                  </td>
                                  <td>
                                    <p>
                                      {item?.assignedDoctor
                                        ? "Dr. " +
                                        item?.assignedDoctor?.firstName.concat(
                                          " ",
                                          item?.assignedDoctor?.lastName
                                        )
                                        : "Not Assigned"}
                                    </p>
                                  </td>
                                  <td>
                                    {Date.now() < moment(item?.date) &&
                                      new Date() >
                                      moment(item?.date) - 10 * 60 * 1000 ? (
                                      <p>
                                        <a
                                          onClick={() => {
                                            handleShow2(item)
                                            setJoinCallDetail(item)
                                            // handleClickJoinCall(item?._id);
                                          }}
                                          // to={`/join-call/${item?._id}`}
                                          className="join_call"
                                        >
                                          Join Call
                                        </a>
                                      </p>
                                    ) : (
                                      <p>
                                        <a
                                          href="#"
                                          className="join_call"
                                          data-bs-toggle="modal"
                                          data-bs-target="#staticBackdrop"
                                          onClick={() => {
                                            setConsId(item?._id);
                                            // setShowVal(true)
                                          }}
                                        >
                                          Manage Booking
                                        </a>
                                      </p>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <div className="d-flex justify-content-center mt-5">
                              No upcoming consultations yet
                            </div>
                          )}
                        </tbody>
                        {/* <tbody className="overflow-y-scroll">
                          {upcomingConsultaion?.length > 0 ? (
                            upcomingConsultaion?.map((item, id) => {
                              return (
                                <div key={id}>
                                  <tr className="box_prop">
                                    <td>
                                      <p>{MomentFunc.Date(item?.date)}</p>
                                    </td>
                                    <td>
                                      <p>{MomentFunc.Time(item?.date)}</p>
                                    </td>
                                    <td>
                                      <p>
                                      {item?.assignedDoctor ?
                                      "Dr. " +
                                        item?.assignedDoctor?.firstName.concat(
                                          " ",
                                          item?.assignedDoctor?.lastName
                                        ) : "Not Assigned"}
                                      </p>
                                    </td>
                                    <td>
                                      {Date.now() < moment(item?.date) &&
                                      new Date() >
                                        moment(item?.date) - 10 * 60 * 1000 ? (
                                        <p>
                                          <Link
                                            to={`/join-call/${item?._id}`}
                                            className="join_call"
                                          >
                                            Join Call
                                          </Link>
                                        </p>
                                      ) : (
                                        <p>
                                          <a
                                            href="#"
                                            className="join_call"
                                            data-bs-toggle="modal"
                                            data-bs-target="#staticBackdrop"
                                            onClick={() => setConsId(item?._id)}
                                          >
                                            Manage Booking
                                          </a>
                                        </p>
                                      )}
                                    </td>
                                  </tr>
                                </div>
                              );
                            })
                          ) : (
                            <div className="d-flex justify-content-center mt-5 ">
                              No upcoming Consultations yet
                            </div>
                          )}
                        </tbody> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body height-330">
                  <div className="consulatation_card">
                    <div>
                      <h3 className="common_title">Past Consultations</h3>
                    </div>
                    <div
                      className={
                        pastConsultaions?.length > 0
                          ? "overflow_table consultation_table upcoming_consultation_table"
                          : "overflow_table consultation_table scroll-hide"
                      }
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Doctor</th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody className="overflow-y-scroll upcomingConsultation">
                          {pastConsultaions?.length ? (
                            pastConsultaions?.map((item, i) => {
                              return (
                                <tr className="box_prop" key={i}>
                                  <td>
                                    <p>{MomentFunc.Date(item?.date)}</p>
                                  </td>
                                  <td>
                                    <p>{MomentFunc.Time(item?.date)}</p>
                                  </td>
                                  <td>
                                    <p>
                                      {item?.assignedDoctor
                                        ? "Dr. " +
                                        item?.assignedDoctor?.firstName.concat(
                                          " ",
                                          item?.assignedDoctor?.lastName
                                        )
                                        : "Not Assigned"}
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      <a
                                        onClick={() => Notes(item)}
                                        className="join_call"
                                      >
                                        View Notes
                                      </a>
                                    </p>
                                  </td>


                                  {/* <td>
                                    <p>
                                      <a
                                        onClick={() => handleShow3(item?._id)}
                                        className="join_call"
                                      >
                                        Review
                                      </a>
                                    </p>
                                  </td> */}
                                  <td>
                                    <p>
                                      {item?.review ? (
                                        <a
                                          onClick={() => handleShow3(item?._id)}
                                          className="join_call"
                                        >
                                          Reviewed
                                        </a>
                                      ) : (
                                        <a
                                          onClick={() => handleShow3(item?._id)}
                                          className="join_call"
                                        >
                                          Review
                                        </a>
                                      )}
                                    </p>
                                  </td>

                                </tr>
                              );
                            })
                          ) : (
                            <div className="d-flex justify-content-center mt-5">
                              No past consultations yet
                            </div>
                          )}
                        </tbody>
                        {/* <tbody className="">
                          {pastConsultaions?.length > 0 ? (
                            pastConsultaions?.map((item, i) => {
                              return (
                                <div key={i}>
                                  {Date.now() > new Date(item?.date) ? (
                                    <tr className="box_prop" key={i}>
                                      <td>
                                        <p>{MomentFunc.Date(item?.date)}</p>
                                      </td>
                                      <td>
                                        <p>{MomentFunc.Time(item?.date)}</p>
                                      </td>
                                      <td>
                                        <p>{item.assignedDoctor ?
                                      "Dr. " +
                                        item?.assignedDoctor?.firstName.concat(
                                          " ",
                                          item?.assignedDoctor?.lastName
                                        ) : "Not Assigned"}</p>
                                      </td>
                                      <td>
                                        <p>
                                          <a
                                            onClick={() => Notes(item)}
                                            className="join_call"
                                          >
                                            View Notes
                                          </a>
                                        </p>
                                      </td>
                                    </tr>
                                  ) : null}
                                </div>
                              );
                            })
                          ) : (
                            <div className="d-flex justify-content-center mt-5 ">
                              No past Consultations yet
                            </div>
                          )}
                        </tbody> */}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 upcoming_consultation">
              {notes ? (
                <ConsultationNotes
                  ref={NotesRef}
                  handleNotesPrint={handleNotesPrint}
                  handleNotesClose={handleClose}
                  notesData={notesData}
                />
              ) : (
                <UpCommingNotes upcomingCons={upcomingConsultaion} />
              )}
            </div>
          </div>
        </div>

        {/*  Appointment Modal */}
        <div
          className="modal fade appointment_modal"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content ">
              <div className="close-btn">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="modal-btn">
                  <button
                    onClick={() => handleUpdate()}
                    data-bs-dismiss="modal"
                    type="button"
                    className="re-schedule-btn"
                  >
                    Re-Schedule
                  </button>
                  <button
                    onClick={() => handleCancel(consId)}
                    data-bs-dismiss="modal"
                    type="button"
                    className="btn btn-outline-danger cancel-btn mt-3"
                  >
                    Cancel Appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <Modal size="lg" show={show2} onHide={handleClose2}>
          <Modal.Header closeButton>
            <Modal.Title>Consent form:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="pop-form-main">
              <h4>Patient Name: {joinCallDetail?.user_id?.firstName + " " + joinCallDetail?.user_id?.lastName}</h4>
              <h4>Date of Birth: {moment(joinCallDetail?.user_id?.dob).format(
                "DD/MM/YYYY"
              )}</h4>
              <h4>Date: {moment(joinCallDetail?.date).format(
                "DD/MM/YYYY"
              )}</h4>

              <p className="mt-4">I, {joinCallDetail?.user_id?.firstName + " " + joinCallDetail?.user_id?.lastName}, hereby consent to participate in an online dental consultation with {joinCallDetail?.doctor_id?.firstName + " " + joinCallDetail?.doctor_id?.lastName} through the use of telecommunication technologies. I understand that the purpose of this consultation is to discuss my dental concerns and potential treatment options.<br />I acknowledge that while the online consultation allows for remote communication, it may not fully replicate an in-person dental examination. I understand that the dentist will rely on the information provided by me and any images or records submitted electronically to assess my dental condition and provide recommendations.</p>
              <p>I am aware of the potential risks and limitations associated with an online consultation, including but not limited to:</p>
              <ul><li>Inability to perform a physical examination, which may affect the accuracy of diagnosis.</li>
                <li>Technical issues or interruptions in the communication technology that could disrupt the consultation.</li>
                <li>Limited ability to provide emergency care or treatment in the event of complications.</li></ul>
              <p>I understand that the information shared during the consultation is confidential and will be treated in accordance with applicable privacy laws and regulations.<br />I acknowledge that the dentist has explained the nature and purpose of the online consultation, as well as any alternative options available to me.<br />I agree to participate in the online dental consultation voluntarily, and I understand that I have the right to withdraw my consent at any time.</p>


              <p>By signing below, I certify that I have read and understood the contents of this consent form, and I consent to proceed with the online dental consultation.</p>

              <h5>Patient's Signature:  {joinCallDetail?.user_id?.firstName + " " + joinCallDetail?.user_id?.lastName}</h5>
              <h5>Date: {moment(joinCallDetail?.date).format(
                "DD/MM/YYYY"
              )}</h5>

              <h5>Dentist's Name (Provider): {joinCallDetail?.doctor_id?.firstName + " " + joinCallDetail?.doctor_id?.lastName}</h5>
              <h5>Date: {moment(joinCallDetail?.date).format(
                "DD/MM/YYYY"
              )}</h5>

              <p className="mt-4">Please note that this consent form is valid for the specific online consultation session indicated above. If further consultations are required, a new consent form may be necessary.</p>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  onChange={(e) => setAgreeTerms(e.target.checked)}
                />
                <label className="form-check-label" for="flexCheckDefault">
                  I consent
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="pop-set-btns">
            <Button variant="secondary" onClick={handleClose2}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              if (!agreeTerms) {
                return toast.info("Please agree to our terms and use.");
              }
              handleClose2();
              setAgreeTerms()
              navigate(`/join-call/${joinCallDetail?._id}`)
            }}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        {/* review modal */}
        <Modal show={show3} onHide={handleClose3} className="rating-main-popup">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="rating-sec">
              <div className="row">
                <div className="col-lg-12">
                  <h3>Rate the quality of the video call</h3>
                </div>
                <div className="col-lg-12 d-flex justify-content-center star-pattern">
                  {/* <Rating onClick={handleRating} initialValue={rating} /> */}
                  <Rating
                    onClick={ handleRating}
                    initialValue={rating}
                    readonly={!!pastConsultaions?.find((item) => item?._id === consultationId)?.review} 
                  />

                </div>
                <div className="col-lg-12 mt-4">
                  <h5>Review(Optional)</h5>
                  <textarea
                    rows={3}
                    placeholder=""
                    className="w-100"
                    value={state.video_call_comment}
                    readOnly={!!pastConsultaions?.find((item) => item._id === consultationId)?.review}
                    onChange={(e) =>
                      setState({
                        ...state,
                        video_call_comment: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {/* <button variant="primary" className=" new-pres" onClick={handleSubmit}>
              Submit
            </button> */}
            {!pastConsultaions?.find((item) => item?._id === consultationId)?.review && (
              <button variant="primary" className="new-pres" onClick={handleSubmit}>
                Submit
              </button>
            )}
          </Modal.Footer>
        </Modal>

      </div>
    </Layout>
  );
}
