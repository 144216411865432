import moment from "moment";
import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import MomentFunc from "../../../utils/MomentDateTime";
import ViewPrescription from "./ViewPrescription";
import { useReactToPrint } from "react-to-print";
import ConsultationNotes from "./ConsultationNotes";
import { useDispatch } from "react-redux";
import { delConsultation } from "../../../Redux/Actions/admin/adminPanel";

const ConsultationsPrescriptions = ({
  id,
  prescriptions,
  upcomingConsultations,
  pastConsultations,
}) => {
  const dispatch = useDispatch();
  const [viewPres, setViewPres] = useState(false);
  const [viewPresData, setViewPresData] = useState(null);
  const [viewConsNotes, setConstNotes] = useState(false);
  const [constNotes, setNotesData] = useState(null);
  const PrescriptionRef = useRef();
  const NotesRef = useRef();

  const handlePresPrint = useReactToPrint({
    content: () => PrescriptionRef.current,
  });
  const handleNotesPrint = useReactToPrint({
    content: () => NotesRef.current,
  });

  const handleClose = () => {
    setViewPres(false);
  };

  const handleNotesClose = () => {
    setConstNotes(false);
  };

  const handleViewPres = (pres) => {
    setViewPres(true);
    setViewPresData(pres);
  };

  const handleViewNotes = (cons) => {
    setConstNotes(true);
    setNotesData(cons);
  };

  const cancelConsultation = (cons) => {
    dispatch(delConsultation(cons?._id));
  };

  return (
    <div className="row">
      <div className="col-xl-6">
        <div className="tbl_user_info">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-wrap  position-relative height45">
                <h4 className="title_text">All Consultations</h4>
              </div>

              {!viewConsNotes ? (
                <div className=" table-responsive table-defaut-design font-weight500  special_days_table">
                  <table className="table common_table  w-100 small-table-font">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Doctor</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody className="td_color table_fix_height">
                      {[...upcomingConsultations, ...pastConsultations].length >
                      0 ? (
                        <>
                          {upcomingConsultations?.map((consultation, i) => {
                            return (
                              <tr key={i} valign="middle">
                                <td>{MomentFunc.Date(consultation?.date)}</td>
                                <td>{MomentFunc.Time(consultation?.date)}</td>
                                <td>Dr. James Pearlman</td>
                                <td className="text-center">
                                  {new Date() < moment(consultation?.date) &&
                                  new Date() >
                                    moment(consultation?.date) -
                                      10 * 60 * 1000 ? (
                                    <Link className="blue-link-under">
                                      Start Call
                                    </Link>
                                  ) : (
                                    <Link
                                      onClick={() =>
                                        cancelConsultation(consultation)
                                      }
                                      className="blue-link-under"
                                    >
                                      Cancel
                                    </Link>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                          {pastConsultations?.map((consultation, i) => {
                            return (
                              <tr key={i} valign="middle">
                                <td>{MomentFunc.Date(consultation?.date)}</td>
                                <td>{MomentFunc.Time(consultation?.date)}</td>
                                <td>{consultation?.assignedDoctor}</td>
                                <td className="text-center">
                                  <Link
                                    onClick={() =>
                                      handleViewNotes(consultation)
                                    }
                                    className="blue-link-under"
                                  >
                                    View
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      ) : (
                        !viewConsNotes && (
                          <tr>
                            <td colSpan={3} valign="middle" className="h-150">
                              <p className="no_content_table">
                                No Consultations Yet
                              </p>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <ConsultationNotes
                  ref={NotesRef}
                  constNotes={constNotes}
                  handleNotesPrint={handleNotesPrint}
                  handleNotesClose={handleNotesClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="tbl_user_info">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center flex-wrap  position-relative height45">
                <h4 className="title_text">Prescriptions</h4>
                <span>
                  <Link to={`/admin/member/newPrescriptions/${id}`}>
                    <button className="btn cre_new">New Prescription</button>
                  </Link>
                </span>
              </div>

              {!viewPres ? (
                <div className="table-responsive table-defaut-design font-weight500 special_days_table">
                  <table className="table common_table w-100 small-table-font ">
                    <thead>
                      <tr>
                        <th>Issue Date</th>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody className="td_color table_fix_height">
                      {prescriptions?.length > 0 ? (
                        prescriptions?.map((prescription) => {
                          return (
                            <tr valign="middle">
                              <td>{MomentFunc.Date(prescription?.date)}</td>
                              <td className="med-td">
                                <p>{prescription?.medication[0]?.meds}</p>
                              </td>
                              <td>{prescription?.medication[0]?.quantity}</td>
                              <td>
                                <Link
                                  onClick={() => handleViewPres(prescription)}
                                  className="light-blue-link-under"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={3} valign="middle" className="h-150">
                            <p className="no_content_table">
                              No Prescriptions Yet
                            </p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <ViewPrescription
                  ref={PrescriptionRef}
                  viewPresData={viewPresData}
                  handlePrint={handlePresPrint}
                  handleClose={handleClose}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationsPrescriptions;
